import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
	selector: 'origen-carga-recaudo-dialog',    
	templateUrl: 'origen-carga-recaudo-dialog.component.html',
})

export class OrigenCargaRecaudoDialogComponent {
    public title: string;
	public message: string;
    public width: string;
    public camaraPresente: boolean;


	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<OrigenCargaRecaudoDialogComponent>
	) {
		this.title = data.title;
        this.message = data.message;
        this.camaraPresente = data.camaraPresente;
	}
    
    onClick(option: string) 
    {
		this.dialogRef.close(option);
	}
}