import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from './auth.service';

import { EntornoService } from './entorno.service';
import { InicioSesionComponent } from './inicio-sesion/inicio-sesion.component';

import { RestablecerPassComponent } from './inicio-sesion/inicio-sesion.component';
import { RegistroComponent } from './registro/registro.component';
import { TerminosComponent } from '../shared/terminos/terminos.component';
import { ConfirmationDialogComponent } from '../shared/dialogs/confirmation-dialog.component';
import { OrigenCargaRecaudoDialogComponent } from '../shared/dialogs/origen-carga-recaudo-dialog';
import { TomarFotoRecaudoDialogComponent } from '../shared/dialogs/tomar-foto-recaudo-dialog';
import { ErrorRecaudoDialogComponent } from '../shared/dialogs/error-recaudo-dialog.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { DirectivesModule } from '../directivas/directives.module';


@NgModule({
  declarations: [
    InicioSesionComponent,
    RestablecerPassComponent,
    RegistroComponent
  ],
  imports: [
    HttpClientModule,
    SharedModule,
    NgxExtendedPdfViewerModule,
    PdfViewerModule,
    DirectivesModule,
    NgxImageZoomModule.forRoot()
  ],
  entryComponents : [
    RestablecerPassComponent,
    TerminosComponent,
    ConfirmationDialogComponent,
    ErrorRecaudoDialogComponent,
    OrigenCargaRecaudoDialogComponent,
    TomarFotoRecaudoDialogComponent
  ],
  exports: [
    InicioSesionComponent,
    RegistroComponent
  ],
  providers: [
    EntornoService,
    AuthService
  ]
})
export class EntornoModule { }