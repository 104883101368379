import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
})


export class TerminosComponent {

}

