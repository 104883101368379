import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import BigNumber from "bignumber.js";
import { Observable } from "rxjs/Observable";
import * as CryptoJS from "crypto-js";

import "rxjs/Rx";

import { environment } from "../../environments/environment";

@Injectable()
export class EntornoService {
  public correo: string = "";
  public saldoDisponible: string = "";
  public saldoDisponibleRaw: string = "";

  public alertaAPIV2: boolean;

  public usuario: string = "0";
  public token: string = "0";
  public ultima_conexion: any;
  public nombre_completo: string;
  public fechaInicio: string = "";
  //public cuenta:

  public caduco_sesion: string;

  constructor(private http: HttpClient) {}

  public pivot_msg: any = {
    act: false,
    type: "0",
    mensaje: "",
  };

  public load: boolean = false;
  public parametros: any = [];

  public last_page: any = null;

  limpiarMonto(montoDecimal) {
    let monto = montoDecimal;
    if (monto.trim().length > 0) {
      monto = new BigNumber(montoDecimal.replace(/[^0-9]/g, ""))
        .div(100)
        .toString();
    }
    return monto;
  }

  pipeDecimalBigNumber(monto) {
    let fmt = {
      prefix: "",
      decimalSeparator: ",",
      groupSeparator: ".",
      groupSize: 3,
      secondaryGroupSize: 3,
    };

    let amount = new BigNumber(monto).toFormat(2, fmt);

    return amount;
  }

  /************************/
  /***********Limpieza final de campos******/
  limpiarCampo(valor, origen) {
    if (origen == "texto-espacio") {
      let campo = valor.replace(/[^A-Za-z0-9 ]/g, "");
      var end = campo.length;

      if (campo.substring(0, 1) == " ") {
        campo = campo.slice(0, -1);
        return campo;
      } else if (
        campo.substring(end - 2, end - 1) == " " &&
        campo.substring(end - 1, end) == " "
      ) {
        campo = campo.slice(0, -1);
        return campo;
      } else {
        return campo;
      }
    } else if (origen == "texto") {
      return valor.replace(/[^A-Za-z0-9]/g, "");
    } else if (origen == "contrasena") {
      return valor.replace(/[^A-Za-z0-9._!?@#$%&*-]*/g, ""); //@,*,#,%,!,-,_, . $,?,&
    } else if (origen == "numeros") {
      return valor.toString().replace(/[^0-9]*/g, "");
    } else if (origen == "solo-texto") {
      return valor.replace(/[^A-Za-z]*/g, "");
    } else if (origen == "solo-texto-espacio") {
      let campo = valor.replace(/[^A-Za-z ]*/g, "");
      var end = campo.length;

      if (campo.substring(0, 1) == " ") {
        campo = campo.slice(0, -1);
        return campo;
      } else if (
        campo.substring(end - 2, end - 1) == " " &&
        campo.substring(end - 1, end) == " "
      ) {
        campo = campo.slice(0, -1);
        return campo;
      } else {
        return campo;
      }
    } else if (origen == "correo") {
      let campo = valor.replace(/[^A-Za-z0-9,._@*-]*/g, "");
      var end = campo.length;
      if (campo.substring(0, 1) == " ") {
        campo = campo.slice(0, -1);
        return campo;
      } else if (
        campo.substring(end - 2, end - 1) == " " &&
        campo.substring(end - 1, end) == " "
      ) {
        campo = campo.slice(0, -1);
        return campo;
      } else {
        return campo;
      }
    } else if (origen == "numeros-espacio") {
      let campo = valor.replace(/[^0-9 ]*/g, "");
      var end = campo.length;
      if (campo.substring(0, 1) == " ") {
        campo = campo.slice(0, -1);
        return campo;
      } else if (
        campo.substring(end - 2, end - 1) == " " &&
        campo.substring(end - 1, end) == " "
      ) {
        campo = campo.slice(0, -1);
        return campo;
      } else {
        return campo;
      }
    } else if (origen == "filtro") {
      return valor.replace(/[^A-Za-z0-9 ,.:;_/!@#$~&-]*/g, "");
    }
  }

  inicializar(token: string) {
    this.token = token;
  }

  iniciarSesion(usuario: string, contrasena: string) {
    const body = {};

    return this.http.post(
      environment.urlApi +
        "/instituciones/" +
        environment.idInstitucion +
        "/afiliados/" +
        usuario +
        "/inicio-sesion?canal=" +
        environment.idCanal,
      body,
      {
        headers: new HttpHeaders({
          Authorization: "Basic " + btoa(usuario + ":" + contrasena),
        }),
        observe: "response",
      }
    );
  }

  cerrarSesion() {
    var auth: any = this.auth();

    const options = {
      headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
    };

    var usuario = auth.user.usuario;

    this.clearSession();

    return this.http.post(
      environment.urlApi +
        "/instituciones/" +
        environment.idInstitucion +
        "/afiliados/" +
        usuario +
        "/cierre-sesion?fechaInicio=" +
        this.fechaInicio,
      null,
      options
    );
  }

  public pullToken() {
    return this.token;
  }

  public pullUser() {
    return this.usuario;
  }

  public setSession(
    token: string,
    usuario: string,
    nombre_completo: string,
    ultima_conexion: string,
    uuid: string,
    tipos_identificacion: any[],
    bancos: any[],
    operadoras: any[],
    cuentas: any[],
    role?,
    nroTelefono?: string,
    documento?: string
  ) {
    localStorage.setItem("token", token);
    localStorage.setItem("usuario", usuario);
    localStorage.setItem("nombre_completo", nombre_completo);
    localStorage.setItem("ultima_conexion", ultima_conexion);
    localStorage.setItem("uuid", uuid);
    localStorage.setItem(
      "tipos_identificacion",
      JSON.stringify(tipos_identificacion)
    );
    localStorage.setItem("bancos", JSON.stringify(bancos));
    localStorage.setItem("operadoras", JSON.stringify(operadoras));
    localStorage.setItem("cuentas", JSON.stringify(cuentas));
    localStorage.setItem("nroTelefono", nroTelefono);
    localStorage.setItem("documento", documento);
    if (role) {
      localStorage.setItem("role", role);
    }
  }

  public clearSession() {
    localStorage.clear();
  }

  public auth() {
    var auth = {};

    if (localStorage.getItem("token")) {
      auth = {
        auth: true,
        user: {
          token: localStorage.getItem("token"),
          usuario: localStorage.getItem("usuario"),
          nombre_completo: localStorage.getItem("nombre_completo"),
          ultima_conexion: localStorage.getItem("ultima_conexion"),
          uuid: localStorage.getItem("uuid"),
          tipos_identificacion: JSON.parse(
            localStorage.getItem("tipos_identificacion")
          ),
          bancos: JSON.parse(localStorage.getItem("bancos")),
          operadoras: JSON.parse(localStorage.getItem("operadoras")),
          cuentas: JSON.parse(localStorage.getItem("cuentas")),
          role: localStorage.getItem("role"),
          documento: localStorage.getItem("documento"),
          nroTelefono: localStorage.getItem("nroTelefono"),
        },
      };
    } else {
      auth = { auth: false };
    }

    return auth;
  }

  reestablecerPassword(user: any) {
    const body: any = {
      canal: 5,
      contrasenaActual: user.clave,
      contrasenaNueva: user.clave1,
    };
    return this.http.post(
      environment.urlApi +
        "/instituciones/" +
        environment.idInstitucion +
        "/usuarios/" +
        user.usuario +
        "/cambio-contrasena",
      body,
      {
        headers: new HttpHeaders({}),
        observe: "response",
      }
    );
  }

  hideAlert() {
    setTimeout(() => {
      //<<<---    using ()=> syntax
      this.pivot_msg.act = false;
    }, 3000);
  }

  datosPerfil() {
    var auth: any = this.auth();

    let url =
      "/instituciones/" +
      environment.idInstitucion +
      "/afiliados/" +
      auth.user.usuario +
      "/perfil?canal=" +
      environment.idCanal +
      "&perfil";

    return this.http
      .get(environment.urlApi + url, {
        headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
      })
      .subscribe(
        (res) => {
          //console.log(res);
          this.correo = res["correo"];
        },
        (err) => console.log(err)
      );
  }

  datosSeguridadl() {
    var auth: any = this.auth();

    let url =
      "/seguridad?canal=5&identificacion=" +
      auth.user.documento +
      "&telefono=" +
      auth.user.nroTelefono;

    return this.http
      .get(environment.urlApi + url, {
        headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
      })
      .subscribe(
        (res) => {
          //console.log(res);
          this.saldoDisponibleRaw = parseFloat(res["saldoDisponible"]).toFixed(
            2
          );
          this.saldoDisponible = res["saldoDisponible"];
          const currency = function (number) {
            return new Intl.NumberFormat("de-DE", {
              minimumFractionDigits: 2,
            }).format(number);
          };
          this.saldoDisponible = currency(this.saldoDisponible);
        },
        (err) => console.log(err)
      );
  }

  datosApi() {
    var auth: any = this.auth();

    let url = environment.urlBackendV2 + "pagos-masivos";

    return this.http
      .get(url, {
        headers: new HttpHeaders({ "X-Auth-Token": auth.user.token }),
      })
      .subscribe(
        (res: any) => {
          console.log(res);
          this.alertaAPIV2 = true;
        },
        (err) => {
          console.log(err);
          this.alertaAPIV2 = false;
        }
      );
  }
}
