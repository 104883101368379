export const environment = {
  production: false,
  //urlApi: '/api/v1',
  //urlApi: 'http://192.168.1.105:8080/api/v1',
  urlApi: "https://comercios.digopago.com/api/v1",
  idInstitucion: 1,
  idCanal: 5,
  // urlBeneficiarios : 'https://comercios.digopago.com/beneficiarios/',
  urlBeneficiarios: "https://comercios.digopago.com/beneficiarios/",
  // urlPagos : 'https://comercios.digopago.com/pagos/'
  urlPagos: "https://comercios.digopago.com/pagos/",

  urlBackendV2: "https://comercios.digopago.com/v2/",
};
