import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
//import { ExcelService } from "../excel/excel.service";
import { Column } from "./sort-columns/sort-columns.component";

// import * as jsPDF from 'jspdf';
// import 'jspdf-autotable';
//import { ExportToCsv } from 'export-to-csv';

@Injectable()
export class SharedService {

	private states: Map<string, any> = new Map<string, any>();

	constructor(
		private datePipe: DatePipe,
		//private excelService: ExcelService
	) { }

	// exportDataToExcel(data: any[], columns: Column[], filename: string): void {
	// 	let rows = data.map((element) => {
	// 		let row: any = {};

	// 		columns.filter((column) => column.exportable).forEach((column) => {
	// 			row[column.name] = element[column.id];
	// 		})

	// 		return row;
	// 	});

	// 	this.excelService.exportAsExcelFile(rows, filename, null);
	// }

	// exportDataToPdf(data: any[], columns: Column[], filename: string, title: string): void {
	// 	let columnNames = columns.map((column) => column.name);

	// 	let rows = data.map((node) => {
	// 		let row: any = [];

	// 		columns.forEach((column, index) => {
	// 			row[index] = node[column.id];
	// 		});

	// 		return row;
	// 	});

	// 	let doc = new jsPDF('landscape', 'mm', 'legal');

	// 	let header = 'PagoNet > ' + title + ' (' + this.datePipe.transform(Date.now(), 'dd/MM/yyyy hh:mmaa') + ')';

	// 	doc.autoTable(columnNames, rows, {
	// 		didDrawPage: function () {
	// 			doc.text(header, 5, 10);
	// 		}
	// 	});

	// 	doc.save(filename + '.pdf');
	// }

	// exportDataToCsv(data: any[], columns: Column[], filename: string): void  {
	// 	let rows = data.map((element) => {
	// 		let row: any = {};

	// 		columns.filter((column) => column.exportable).forEach((column) => {
	// 			row[column.name] = element[column.id];
	// 		})

	// 		return row;
	// 	});
		   
	// 	const options = {
	// 		fieldSeparator: ',',
	// 		quoteStrings: '"',
	// 		decimalSeparator: '.',
	// 		showLabels: true,
	// 		showTitle: true,
	// 		title: filename,
	// 		filename: filename,
	// 		useTextFile: false,
	// 		useBom: true,
	// 		useKeysAsHeaders: true,
	// 		// headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
	// 	};

	// 	const csvExporter = new ExportToCsv(options);

	// 	csvExporter.generateCsv(rows);
	// }

	exportDataToClipboard(data: any[], columns: Column[]) {
		let columnNames = columns.map((column) => column.name).toString().replace(/,/g, '  ');
		let rows = data.map((node) => {
			let row: any = [];
			columns.forEach((column, index) => {
				row[index] = node[column.id];
			});
			return row;
		});

		let output = rows.map( row => (row.toString()).replace(/,/g, '  ') + '\n')
		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', (columnNames + '\n' + output.toString().replace(/,/g, '')));
			e.preventDefault();
			document.removeEventListener('copy', null);
		});
		document.execCommand('copy');
	}

	exportDataToPrint(data: any[], columns: Column[], fileName: string) {
		// https://www.npmjs.com/package/ng2-pdf-viewer
		// generar pdf y verlo en viewer?
		// https://stackoverflow.com/questions/44244148/how-to-print-pdf-in-angular-2
		// hacer un frame y imprimir
		// puede ser una mezcla de lo que se hace y los anteriores. https://stackoverflow.com/questions/16894683/how-to-print-html-content-on-click-of-a-button-but-not-the-page

		let w=window.open();
        let header = 'PagoNet > ' + fileName + ' (' + this.datePipe.transform(Date.now(), 'dd/MM/yyyy hh:mmaa') + ')';
        w.document.write(
        `<head>
          <style>
            * {
              -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
              color-adjust: exact !important;  /*Firefox*/
            }
            table {
              border-collapse: collapse;
            }
            th {
              padding-left: 15px; 
              padding-rigth: 15px;
              background: #2980BA; 
              color: white; 
            }
            tr:nth-child(even) td { 
              background: #F1F1F1; 
            }  
            tr:nth-child(odd) td { 
              background: #FEFEFE; 
            }
            td {
              padding-left: 15px; 
              padding-rigth: 15px;
              font-familly: sans-serif;
              color: #50576A;
              font-size: 14px;
            }
          </style>
        </head>`)
        w.document.write('<h3>' + header + '<\/h3>');
        w.document.write('<table>');
        w.document.write('<tr>');
        columns.forEach( column => {
          w.document.write('<th><b>' + column.name + '</b><\/th>');
        });
        w.document.write('<\/tr>');
        data.map((node) => {
          w.document.write('<tr>');
          columns.forEach(column => {
            // row[index] = node[column.id];
            w.document.write('<td>' + node[column.id] + '<\/td>');
          });
          w.document.write('<\/tr>');
        })
        w.document.write('<\/table>');
        w.print();
        w.close();
	}

	loadState(key: string): any {
		return this.states.get(key);
	}

	saveState(key: string, state: any): void {
		this.states.set(key, state);
	}

}