import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';

@Component({
	selector: 'tomar-foto-recaudo-dialog',    
	templateUrl: 'tomar-foto-recaudo-dialog.component.html',
    styleUrls: ['tomar-foto-recaudo-dialog.component.css']
})

export class TomarFotoRecaudoDialogComponent {

    @Output()
    public pictureTaken = new EventEmitter<WebcamImage>();
    //  toggle webcam on/off
    public showWebcam = true;
    public allowCameraSwitch = true;
    public multipleWebcamsAvailable = false;
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
      // width: {ideal: 1024},
      // height: {ideal: 576}
    };
    public errors: WebcamInitError[] = [];
  
    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

    public webcamImage: WebcamImage = null;

    //------
    public title: string;
	public message: string;
    public width: string;
    public height: string;

    public dataUrlString: string;

    @ViewChild("video")
    public video: ElementRef;

    @ViewChild("canvas")
    public canvas: ElementRef;

    public captures: Array<any>


	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<TomarFotoRecaudoDialogComponent>
	) {
		this.title = data.title;
        this.message = data.message;
        this.height = data.height;
        this.captures = [];
	}
    
    onClick(option: string) 
    {
        if(option == 'Capturar')
        {
            this.capture();
        }
        else
        {
            this.dialogRef.close(null);
        }
    }
    
    public ngAfterViewInit() 
    {
        WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => 
        {
            this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        });
    }

    public capture() 
    {
        this.trigger.next();
    }

    public handleInitError(error: WebcamInitError): void 
    {
        console.log("Error: ",error.message)
        this.dialogRef.close("err-"+error.message);
        this.errors.push(error);
    }

    public handleImage(webcamImage: WebcamImage): void 
    {
        //console.info('received webcam image', webcamImage.imageAsDataUrl);
        this.dataUrlString = webcamImage.imageAsDataUrl;
        this.dialogRef.close("img-"+this.dataUrlString);
        //this.pictureTaken.emit(webcamImage);
    }

    public get triggerObservable(): Observable<void> 
    {
        return this.trigger.asObservable();
    }
    
    public get nextWebcamObservable(): Observable<boolean|string>
    {
        return this.nextWebcam.asObservable();
    }

    
}
