import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { EntornoModule } from './entorno/entorno.module';
import { SharedModule } from './shared/shared.module';

import { MatPaginatorIntl } from "@angular/material";

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { NgxImageZoomComponent, NgxImageZoomModule } from 'ngx-image-zoom';

import { DirectivesModule } from '../app/directivas/directives.module';

const spanishRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 de ${length}`; }
  
  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} de ${length}`;
}


export function getDutchPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  
  paginatorIntl.itemsPerPageLabel = 'Elementos por Pagina:';
  paginatorIntl.firstPageLabel = 'Primera Página';
  paginatorIntl.lastPageLabel = 'Ultima Página';
  paginatorIntl.nextPageLabel = 'Página Siguiente';
  paginatorIntl.previousPageLabel = 'Página Anterior';

  paginatorIntl.getRangeLabel = spanishRangeLabel;
  
  return paginatorIntl;
}


@NgModule({
  declarations: [ AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    EntornoModule,
    SharedModule,
    NgxExtendedPdfViewerModule,
    NgxImageZoomModule,
    DirectivesModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {}