
import { Directive, Output, EventEmitter, HostListener} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appNumeros]'
})
export class SoloNumerosDirective {

    constructor(public model: NgControl) { }

    @Output() limpiarNumeros: EventEmitter<any> = new EventEmitter<any>();

    @HostListener('ngModelChange', ['$event'])

    onInputChange(event: any) {
          let valor = event.replace(/[^0-9]/g, '');
        
          this.model.valueAccessor.writeValue(valor);
          this.limpiarNumeros.emit(valor);
         
     
    }

  

}
  

