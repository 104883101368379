import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';

import { SharedService } from './shared.service';
import { CompletedDialogComponent } from './dialogs/completed-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog.component';
import { ErrorRecaudoDialogComponent } from './dialogs/error-recaudo-dialog.component';

import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { MaterialModule } from './material/material.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { SortColumnsComponent } from './sort-columns/sort-columns.component';
import { TerminosComponent } from './terminos';

import { NgQrScannerModule } from 'angular2-qrscanner';
import { EscanerQRComponent } from './escaner-qr/escaner-qr.component';
import { OrigenCargaRecaudoDialogComponent } from './dialogs/origen-carga-recaudo-dialog';
import { TomarFotoRecaudoDialogComponent } from './dialogs/tomar-foto-recaudo-dialog';
import { WebcamComponent } from 'ngx-webcam';
import {WebcamModule} from 'ngx-webcam';
import { DirectivesModule } from '../directivas/directives.module';


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    SortColumnsComponent,
    EscanerQRComponent,
    TerminosComponent,
    ConfirmationDialogComponent,
    ErrorRecaudoDialogComponent,
    CompletedDialogComponent,
    OrigenCargaRecaudoDialogComponent,
    TomarFotoRecaudoDialogComponent
  ],
  imports: [ 
    CommonModule,
    MaterialModule,
    NgQrScannerModule,
    WebcamModule,
    DirectivesModule
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    SortColumnsComponent,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    RouterModule,
    CompletedDialogComponent,
    TerminosComponent,
    WebcamModule,
    DirectivesModule
   ],
   providers: [SharedService],
   entryComponents:[WebcamComponent]
})
export class SharedModule { }