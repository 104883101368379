import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, FormArray, Form, } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators, PATTERN_EMAIL } from '../../custom-validators';
import { AuthService, Afiliacion, ValidacionUsuario, PreguntasDesafio, RegistroUsuario } from '../auth.service';
import { Location } from '@angular/common';
import { ErrorStateMatcher } from '@angular/material/core';
import { WebcamImage } from 'ngx-webcam';
import { EntornoService } from '../entorno.service';
import * as moment from 'moment';
//
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


import { responseErrorParser } from '../../app.component';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';
import { OrigenCargaRecaudoDialogComponent } from '../../shared/dialogs/origen-carga-recaudo-dialog';
import { TomarFotoRecaudoDialogComponent } from '../../shared/dialogs/tomar-foto-recaudo-dialog';
import { SubjectsService } from '../../shared/subjects/subjects';
import { DomSanitizer } from '@angular/platform-browser';
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ErrorRecaudoDialogComponent } from '../../shared/dialogs/error-recaudo-dialog.component';
import { RequiredValidator, REQUIRED_VALIDATOR } from '@angular/forms/src/directives/validators';
import { p } from '@angular/core/src/render3';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})



export class RegistroComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
//
  public form: FormGroup;
  public formClave: FormGroup;
  public formSubAct: FormGroup;
  public formRecaudos: FormGroup;
  public formCrearUsuario: FormGroup;
  public message: string;
  isLinear = true;
  public loading: boolean;
  public actividad: boolean = false;
  public registroStatus: string;
  public registroMessage: string;
  public confirm: boolean = false;
  public numeroDoc: boolean = true;
  public numeroTlf: boolean = true;
  public numeroRM: boolean = true;
  public numCuenta: boolean = true;
  public numPor: boolean = true;
  public numBanco: boolean = true;
  public errorCuentaMenosDe20Dig: boolean = false;
  public tamCuentas: number = 0;
  public typeFile: boolean = false;

  public benef: boolean = false;
  public suple: boolean = false;
  public afi: boolean = false;
  public soloSup: boolean = false;

  public edadLimite: number = 0;
  public edadFallo: boolean = false;


  public cuentaIndex: any;
  public unique:boolean = false;

  public preguntaRespuestaPersonalizada = {
    id: null,
    pregunta: null,
    respuesta: null,
    confirmarRespuesta: null,
    options: [],
    errorPreguntaVacia: false,
    errorPreguntaIncorreta: false,
    errorRespuestaIncorrecta: false,
    errorMinLength: false,
    errorRepetido: false,
    errorConfirmacionMatch: false,
    errorEmpty: false
  };

  //Array que contiene los tipos de identificadores de Cedula o Rif
  public tipoOptions: any[]
  //Variable para mostrar ciertos mensajes de error en las páginas
  //(Esto si quieres donde se llama se puede modificar y mostrar un popup)
  public errorMessage = '';

  //Interface donde se guardarán los datos para las validaciones
  public afiliacion: Afiliacion;
  //Se modifico el tipo de dato de la variable para ser mas restrictivo
  public tipoCliente: 'Personas' | 'Empresas' | 'personas' | 'empresas';

  //Variable que contiene la lista de todas las posibles preguntas de seguridad
  public preguntaOptions = [];
  //Variable para saber cuantas preguntas de desafio se van a pedir
  public preguntasDesafioRequeridas = 0;
  //Variable que contiene los datos de las preguntas y respuestas seleccionadas
  public preguntasSeleccionadas: PreguntasDesafio[] = [];
  public  preguntasObj:PreguntasDesafio[] = [];
  //Si se requiere para la validacion
  public palabrasReservadas: any[]; //Palabras que no se pueden colocar en las respuestas de seguridad
  //Variable que contiene los codigo de area de los telefonos
  public codigoOptions = [];
  // Variables quue contiene la lista de los ids de preguntas seleccionadas para filtrar las opciones
  public idSeleccionados = [];
  // Variable para obtener valores anteriores
  public preguntaAbierta = undefined;
  // Validacion Form Preguntas
  public preguntasInvalid: boolean;

  //Form de Dirección
  public formDireccion: FormGroup;

  //Form de cuenta
  public formCuentaCliente: FormGroup;

  //Dirección

  //Regiones
  public regiones = [];
  public estados = [];
  public ciudades = [];
  public municipios = [];
  public municipiosarr = [];
  public parroquias = [];
  public zonas = [];
  public sexos = [];
  public profesiones = [];
  public edociviles = [];
  public zonaPostal: any;
  public fechaMax: string = this.fechaHoy();

  public mostrar: boolean = false;
  public mostrarC: boolean = false;
  public mostrarM: boolean = false;
  public mostrarP: boolean = false;
  public mostrarZ: boolean = false;
  public mostrarR: boolean = false;
  public mostrarZP: boolean = false;

  public actividadestodas = [];
  public actividades = [];
  public subactividades = [];

  public countAcept: number = 1;


  public bancos = [];

  public steep = 1;
  public aceptoTerminos = false;
  public hasRif = false;
  public rifValid = false;
  public errorRifExiste = false;
  public errorMessageUsuario = false;
  public errorMatch = false
  public otpValidada = false;

  public cuentasPrevias = [];
  public actualizarCuentas: boolean;
  public idsDeCuentasParaActualizar = [];
  public cuentaAdicionalAgregada: boolean = false;
  public cuentasEnviadasAlApi: boolean = false;
  public cuentasActEnviadasAlApi: boolean = false;
  public formDeRecaudosCargados: boolean = false;

  public arrayDeCuentas: FormArray;

  public adjunto = {};

  contador = 300;
  cuentaRegresiva;
  isSentOtp = false;


  public comprobante: File;

  public fileInput = {
    /*  rifComercio: 'rifComercio' ,
      registroMercantil: 'registroMercantil',
      rifRep: 'rifRep',
      cedulaRep: 'cedulaRep',
      soporteCuenta: 'soporteCuenta',
      soporteAfiliado: 'soporteAfiliado',
      cheque: 'cheque',*/
  };
  public recaudos = [];
  public recaudosMostrar = [];
  public recaudosObli=[];
  public arregloBase64 = [];
  public recaudosCompletos: boolean = false;
  public camaraDisponible: boolean = true;
  public fotoTomada: boolean = false;
  public fileBlob: any;
  public fileUrls;
  public zoom: number = 0.4;
  public webcamImage: WebcamImage = null;
  public imagePath;
  imgURL: any;
  public archivoPermitido:boolean;

  @Input() recaudosCargados: any[] = [];


  public filesArray: any[] = [];
  public recaudoDatosZoom: string;
  public pdfViewerActive = "";
  public recaudosObligatorios: number = 0;
  public tamanoArchivos: number;
  public archivosIguales: boolean = false;
  public archivoYaSubido: boolean = false;
 
  public thumbnail: any;
  private sanitizer:DomSanitizer;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private locationRef: Location,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private subjectsService: SubjectsService,
    private entornoService: EntornoService
  ) {
    //Se ajusto la url para acceder al registro para poder pasar el parametro de tipo de cliente
    //Ya que por variable de sesion no estaba funcionando

    this.activatedRoute.params.subscribe(params => {

      if (params['tipoCliente'] != undefined &&
        (params['tipoCliente'] == 'personas' || params['tipoCliente'] == 'empresas')) {
        this.tipoCliente = params['tipoCliente'] == 'personas' ? 'Personas' : 'Empresas';
        localStorage.setItem('tipoCliente', this.tipoCliente);
      }
      else {
        this.router.navigate(['/inicio-sesion']);
      }
    });

    this.formCrearUsuario = this.formBuilder.group({
      contrasena: ['', [Validators.required]],
      contrasenaConfirm: ['']
    }, { validator: this.checkPasswords });
  }


  ngOnInit() {

    this.loading = false;
    this.preguntasInvalid = true;
    this.onTipoIdentificacion();
    this.onCodigosArea();

  /*  this.authService.bancos().subscribe((res: any) => {


      this.loading = false;
      if (res && res.errorMessage != undefined) {
        this.errorMessage = res.errorMessage;
      }
      else {
        this.bancos = res;
        //this.buscarCuentasPreviasRegistro();


        if (this.suple == true && this.benef == false) {
          this.steep++;
          this.nextSteep();
        }
        if (this.benef == true || this.afi == true) {
          this.nextSteep();
        }

      }
    }, error => {
      this.loading = false;
      let e = responseErrorParser(error);
      this.errorMessage = e.errorMessage;

    });*/

    this.formRecaudos = this.formBuilder.group({});

    if (this.tipoCliente.toLowerCase() == 'empresas') {
      let cuentasArray = new FormArray([
        new FormGroup({
          'banco': new FormControl(null, Validators.required),
          'cuenta': new FormControl(null, [Validators.required, Validators.minLength(20), Validators.maxLength(20)]),
          'tipocuenta': new FormControl(null, Validators.required),
          'porcentaje': new FormControl(null, Validators.required),
          'errorItem': new FormControl(false),
          'errorPorc': new FormControl(false)
        })
      ])

      this.formCuentaCliente = new FormGroup({
        'cuentasArray': cuentasArray
      });

      this.arrayDeCuentas = cuentasArray;

      this.form = this.formBuilder.group({
        tipoDocumento: [null, [Validators.required]],
        numeroDocumento: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(9)]],
        correo: [null, [Validators.required]],
        correoConfirm: [null, [Validators.required]],
        codigoTelefono: [null, [Validators.required]],
        telefono: [null, [Validators.required]],
        registroMercantil: [null, [Validators.required, Validators.maxLength(8)]],
        nombre: [null, [Validators.required]],

      },
        {
          validator: CustomValidators.emailMatchValidator
        });

      this.formDireccion = this.formBuilder.group({
        region: [null, [Validators.required]],
        estado: [null, [Validators.required]],
        municipio: [null, [Validators.required]],
        parroquia: [null, [Validators.required]],
        ciudad: [null, [Validators.required]],
        sector: [null, [Validators.required]],
        calleAv: [null, [Validators.required]],
        casaEdificio: [null, [Validators.required]],
        piso: [null, [Validators.required]],
        agente: [null, [Validators.required]],
        contribuyente: [null, [Validators.required]],
        actividadeconomica: [null, [Validators.required]],
        subactividad: [null],
        fecha: [null, [Validators.required]],
        monto: [null, [Validators.required]]
      });

    } else {

      let cuentasArray = new FormArray([
        new FormGroup({
          'banco': new FormControl(null, Validators.required),
          'cuenta': new FormControl(null, [Validators.required, Validators.minLength(20), Validators.maxLength(20)]),
          'tipocuenta': new FormControl(null, Validators.required),
          'errorItem': new FormControl(false)
        })
      ])

      this.formCuentaCliente = new FormGroup({
        'cuentasArray': cuentasArray
      });

      this.arrayDeCuentas = cuentasArray;

      this.form = this.formBuilder.group({
        tipoDocumento: [null, [Validators.required]],
        numeroDocumento: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(8)]],
        correo: [null, [Validators.required]],
        correoConfirm: [null, [Validators.required]],
        codigoTelefono: [null, [Validators.required]],
        telefono: [null, [Validators.required]],
        nombre: [null, [Validators.required]]
      },
        {
          validator: CustomValidators.emailMatchValidator
        });
      this.formDireccion = this.formBuilder.group({
        region: [null, [Validators.required]],
        estado: [null, [Validators.required]],
        municipio: [null, [Validators.required]],
        parroquia: [null, [Validators.required]],
        ciudad: [null, [Validators.required]],
        sector: [null, [Validators.required]],
        calleAv: [null, [Validators.required]],
        casaEdificio: [null, [Validators.required]],
        piso: [null, [Validators.required]],
        ptoReferencia: [null, [Validators.required]],
        edocivil: [null, [Validators.required]],
        sexo: [null, [Validators.required]],
        profesion: [null, [Validators.required]],
        fecha: [null, [Validators.required]],
        monto: [null, [Validators.required]]
      });
    }

    this.formClave = this.formBuilder.group({
      clave: [null, [Validators.required]]
    });

    //Este formulario es para las validaciones de las preguntas
    //Falta por hacerlo
    // this.formPreguntas = this.formBuilder.group({
    //   preguntas: ['']
    // });

    //El form del usuario se crea al pasar la validacion del rif
    this.formCrearUsuario = this.formBuilder.group({

    });

    this.scrollTopTerms();
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.contrasena.value;
    let confirmPass = group.controls.contrasenaConfirm.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  validarContrasena(usuario: string, docUsuario: string, name: string, palabrasProhibidas: any, contrasena: string) {

    let strNombre: string[] = name.replace(',', '').split(' ');

    for (let i = 0; i < strNombre.length; i++) {
      if (contrasena.toLowerCase().indexOf(strNombre[i].toLowerCase()) >= 0) {
        return false;
      }
    }

    for (let i = 0; i < palabrasProhibidas.length; i++) {
      if (contrasena.toLowerCase().indexOf(palabrasProhibidas[i].toLowerCase()) >= 0) {
        return false;
      }
    }

    if (contrasena.indexOf(docUsuario.substring(1)) >= 0) {
      return false;
    }

    if (contrasena.toLowerCase().indexOf(usuario.toLowerCase()) >= 0) {
      return false;
    }


    let expregular = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~])(?!.*(.)\1{2})[A-Za-z\d!"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~]{8,16}$/g);

    return expregular.test(contrasena);
  }



  fechaHoy(): string {
    var today: any = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }

  onTipoIdentificacion() {
    this.loading = true;
    let esParaAmbos = this.tipoCliente == 'Personas' ? 'B' : 'A'

    this.authService.loadTiposIdentificacion(esParaAmbos)
      .finally(() => this.loading = false)
      .subscribe(response => {
        this.tipoOptions = response;

      })
  }

  /**
   * Método para obtener los codigos de area
   */
  onCodigosArea() {
    this.loading = true;
    this.authService.loadCodigosArea()
      .finally(() => this.loading = false)
      .subscribe(response => {
        let codigos: Array<any> = response;
        for (let i = 0; i < codigos.length; i++){
              if(codigos[i].codigo.substring(0,2) == "04"){
                this.codigoOptions.push(codigos[i]);
              }
        }
   
      })
  }

  /**
   * Método para validar el rif
   */
  validarRif() {
    let nombre = this.entornoService.limpiarCampo(this.form.controls['nombre'].value,"solo-texto-espacio");
    this.form.controls['nombre'].setValue(this.entornoService.limpiarCampo(this.form.controls['nombre'].value,"solo-texto-espacio"));
    this.form.controls['telefono'].setValue(this.entornoService.limpiarCampo(this.form.controls['telefono'].value,"numeros"));
    this.form.controls['correo'].setValue(this.entornoService.limpiarCampo(this.form.controls['correo'].value, "correo"));
    this.form.controls['correoConfirm'].setValue(this.entornoService.limpiarCampo(this.form.controls['correoConfirm'].value, "correo"));
    this.form.controls['numeroDocumento'].setValue(this.entornoService.limpiarCampo(this.form.controls['numeroDocumento'].value, "numeros"));

    this.errorMessage = "";
    if(this.tipoCliente == 'Personas' && nombre !=null && 
      (nombre.substring(0,1)==" " || nombre.substring(nombre.length-1,nombre.length)==" ")){
        this.errorMessage = "El nombre y apellido no debe contener espacios al principio ni al final, tampoco más de un espacio entre palabras. Por favor elimine el espacio del final";
    }else if(this.tipoCliente == 'Empresas' && nombre !=null && 
    (nombre.substring(0,1)==" " || nombre.substring(nombre.length-1,nombre.length)==" ")){
      this.errorMessage = "La razón social no debe contener espacios al principio ni al final, tampoco más de un espacio entre palabras. Por favor elimine el espacio del final";
    }else{
this.dialog.open(ConfirmationDialogComponent,
      {
        disableClose: true ,
        data:
        {
          title: 'Afiliación',
          message: 'Se le enviará una clave temporal al correo "' + this.form.controls['correo'].value + '" y al número de teléfono ' + this.form.controls['codigoTelefono'].value + this.form.controls['telefono'].value + ', Por lo que deberá tener el correo y número activo para su recepción.'
        },
        width: '400px'
      })
      .afterClosed().subscribe((option: string) => {
        if (option === 'Si') {
          this.errorMessage = null;
          this.errorRifExiste = null;
          this.loading = true;
          let identificacion;

          identificacion = this.form.controls['tipoDocumento'].value + this.form.controls['numeroDocumento'].value;

          this.authService.validarIdentificacion(identificacion, this.tipoCliente)
           
            .subscribe(() => {
              this.loading = true
              this.afiliar(this.tipoCliente);
            }, error => {
              let e: any = responseErrorParser(error);
              this.loading = false;
              //Capturamos si el codigo es porque el rif ya existe
              if (e.codigo == 563) {
                this.errorRifExiste = true;
                this.loading = false;
              } else {
                this.errorMessage = e.errorMessage;
                this.loading = false;
              }


            })
        }
      });
    }
    
  }

  convertFecha(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  agregarOtraCuenta() {
    //TO DO
    this.cuentaAdicionalAgregada = true;
    if (this.tipoCliente.toLowerCase() == 'empresas') {
      (<FormArray>this.formCuentaCliente.get('cuentasArray')).push(
        new FormGroup({
          'banco': new FormControl(null, Validators.required),
          'cuenta': new FormControl(null, [Validators.required, Validators.minLength(20), Validators.maxLength(20)]),
          'tipocuenta': new FormControl(null, Validators.required),
          'porcentaje': new FormControl(null, Validators.required),
          'errorItem': new FormControl(false),
          'errorPorc': new FormControl(false)
        }));
    } else {
      (<FormArray>this.formCuentaCliente.get('cuentasArray')).push(
        new FormGroup({
          'banco': new FormControl(null, Validators.required),
          'cuenta': new FormControl(null, [Validators.required, Validators.minLength(20), Validators.maxLength(20)]),
          'tipocuenta': new FormControl(null, Validators.required),
          'errorItem': new FormControl(false)
        }));
    }
  }

  ampliarFormularioParaCuentasPrevias() {
    //TO DO
    if (this.tipoCliente.toLowerCase() == 'empresas') {
      (<FormArray>this.formCuentaCliente.get('cuentasArray')).push(
        new FormGroup({
          'banco': new FormControl(null, Validators.required),
          'cuenta': new FormControl(null, [Validators.required, Validators.minLength(20), Validators.maxLength(20)]),
          'tipocuenta': new FormControl(null, Validators.required),
          'porcentaje': new FormControl(null, Validators.required),
          'errorItem': new FormControl(false),
          'errorPorc': new FormControl(false)
        }));
    } else {
      (<FormArray>this.formCuentaCliente.get('cuentasArray')).push(
        new FormGroup({
          'banco': new FormControl(null, Validators.required),
          'cuenta': new FormControl(null, [Validators.required, Validators.minLength(20), Validators.maxLength(20)]),
          'tipocuenta': new FormControl(null, Validators.required),
          'errorItem': new FormControl(false)
        }));
    }
  }

  eliminarCuenta() {
    let lastIndex: number = (<FormArray>this.formCuentaCliente.get('cuentasArray')).length - 1;
    let cuentas = [];
    let cuenta;

    if (this.tipoCliente.toLowerCase() == 'empresas') {
      cuenta = {
        id: this.idsDeCuentasParaActualizar[lastIndex],
        nroCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(lastIndex).value.cuenta,
        tipoCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(lastIndex).value.tipocuenta,
        porcLiq: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(lastIndex).value.porcentaje
      }
    }
    else {
      cuenta = {
        id: this.idsDeCuentasParaActualizar[lastIndex],
        nroCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(lastIndex).value.cuenta,
        tipoCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(lastIndex).value.tipocuenta
      }
    }
    cuentas.push(cuenta);
    
    if (this.idsDeCuentasParaActualizar.length > 0 && this.actualizarCuentas && this.idsDeCuentasParaActualizar.length == (<FormArray>this.formCuentaCliente.get('cuentasArray')).length) {
      this.authService.deshabilitarCuentaEspecificaRegistradaPreviamente(this.afiliacion.identificacion, cuentas).subscribe((res: any) => {
        this.loading = false;
        if (res && res.errorMessage != undefined) {
          this.errorMessage = res.errorMessage;
        }
      }, error => {
        this.loading = false;
        let e = responseErrorParser(error);
        this.errorMessage = e.errorMessage;
      });
    }
    (<FormArray>this.formCuentaCliente.get('cuentasArray')).removeAt(lastIndex);
    if (this.cuentaAdicionalAgregada) {
      this.cuentaAdicionalAgregada = false;
      (<FormArray>this.formCuentaCliente.get('cuentasArray')).removeAt(lastIndex);
    }
    else if (this.idsDeCuentasParaActualizar.length > 0 && this.actualizarCuentas) {
      this.idsDeCuentasParaActualizar.splice(-1, 1);
    }
  }

  enviarCuenta() {

    this.loading = true;
    this.errorMessage = null;

    let listaDeCuentas = [];
    let countErr = 0;
    let iguales= 0;
    if ((<FormArray>this.formCuentaCliente.get('cuentasArray')).length > 0) {
      for (let i = 0; i < (<FormArray>this.formCuentaCliente.get('cuentasArray')).length; i++) {
      let pivote =(<FormArray>this.formCuentaCliente.get('cuentasArray')).at(i).parent.controls[i].controls['cuenta'].value  ;
        for (let j = 0; j < (<FormArray>this.formCuentaCliente.get('cuentasArray')).length; j++) {
          let frecuente =(<FormArray>this.formCuentaCliente.get('cuentasArray')).at(j).parent.controls[j].controls['cuenta'].value  ;
          if(pivote== frecuente && i != j){
            this.unique=false;
            iguales = j;
            this.loading= false;
             (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(j).parent.controls[j].controls['errorItem'].setValue(true);
             break;
          }else{
            this.unique=true;
            iguales = 0;
            (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(j).parent.controls[j].controls['errorItem'].setValue(false);
        
          }
        }
        if(iguales>0){
            break;
        }
      }
    }
    if(iguales == 0){
      if (!this.actualizarCuentas) 
      {
  
        if ((<FormArray>this.formCuentaCliente.get('cuentasArray')).length == 0) {
          this.loading = false;
        }
  
        for (let index = 0; index < (<FormArray>this.formCuentaCliente.get('cuentasArray')).length; index++) {
          let formValue = (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value;
  
          let porcentaje;
  
  
          if (this.tipoCliente.toLowerCase() == 'empresas') {
            porcentaje = formValue.porcentaje;
            if (porcentaje != null) {
              if (porcentaje.toString().indexOf(',') > -1) {
                porcentaje = porcentaje.replace(',', '.');
              }
            }
          } else {
            porcentaje = null;
          }
  
  
          if (formValue.banco.codigo.toString() != formValue.cuenta.substring(0, 4).toString()) {
            countErr++;
  
            this.loading = false;
            this.numBanco = false;
            return (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(true);
  
          }
          else {
            (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(false);
            this.numBanco = true;
  
            if(this.tipoCliente.toLowerCase() == 'empresas')
            {
              let cuentaBancaria =
              {
                banco: formValue.banco.codigo,
                nroCuenta: formValue.cuenta,
                tipoCuenta: formValue.tipocuenta,
                porcLiq: this.entornoService.limpiarMonto(porcentaje)
              };
              listaDeCuentas.push(cuentaBancaria);
            }
            else
            {
              let cuentaBancaria =
              {
                banco: formValue.banco.codigo,
                nroCuenta: formValue.cuenta,
                tipoCuenta: formValue.tipocuenta
              };
              listaDeCuentas.push(cuentaBancaria);
            }
  
          }
        }
        let cuentacliente = {
          cuentas: listaDeCuentas
        };
        if (listaDeCuentas.length > 0 && countErr == 0 && listaDeCuentas.length == (<FormArray>this.formCuentaCliente.get('cuentasArray')).length) {
          if (this.tipoCliente.toLowerCase() == 'empresas') 
          {
            if (this.validarPorcentajeCuentas()) 
            {
              this.enviarDatosBancarios(cuentacliente, this.afiliacion.identificacion + "");
            }
            else 
            {
              this.errorMessage = "El total del porcentaje de liquidación debe ser 100%";
              this.loading = false;
            }
          } 
          else 
          {
            this.enviarDatosBancarios(cuentacliente, this.afiliacion.identificacion + "");
            this.loading = false;
          }
  
        }
      }
      else 
      {
        let cuentas = []
        if (!this.cuentaAdicionalAgregada) 
        {
        
          for (let index = 0; index < (<FormArray>this.formCuentaCliente.get('cuentasArray')).length; index++) 
          {
  
            let formValue = (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value;
  
            let porcentaje;
  
  
            if (this.tipoCliente.toLowerCase() == 'empresas') {
              porcentaje = formValue.porcentaje;
              if (porcentaje != null) {
                if (porcentaje.toString().indexOf(',') > -1) {
                  porcentaje = porcentaje.replace(',', '.');
                }
              }
            } else {
              porcentaje = null;
            }
  
  
            if (formValue.banco.codigo.toString() != formValue.cuenta.substring(0, 4).toString()) {
  
              countErr++;
  
              this.loading = false;
              this.numBanco = false;
              return (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(true);
  
  
            } else {
  
              (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(false);
              this.numBanco = true;
  
              let cuenta;
              let porc = null;
              if(this.tipoCliente == "Empresas"){
                  porc = this.entornoService.limpiarMonto((<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value.porcentaje);
              }
              cuenta = {
                id: this.idsDeCuentasParaActualizar[index],
                nroCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value.cuenta,
                tipoCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value.tipocuenta,
                porcLiq: porc
              };
              cuentas.push(cuenta);
            }
          }
          if (cuentas.length > 0 && countErr == 0 && cuentas.length == (<FormArray>this.formCuentaCliente.get('cuentasArray')).length) 
          {
              this.authService.actualizarCuentasRegistradasPreviamente(this.afiliacion.identificacion, cuentas).subscribe((res: any) => {
                this.loading = false;
                if (res && res.errorMessage != undefined) {
                  this.errorMessage = res.errorMessage;
                }
                else {
                  // this.cuentasEnviadasAlApi = true;
                  this.nextSteep();
                }
              }, error => {
                this.loading = false;
                let e = responseErrorParser(error);
                this.errorMessage = e.errorMessage;
              });
     
          }
        }
        else 
        {
          let cuenta;
          for (let index = 0; index < (<FormArray>this.formCuentaCliente.get('cuentasArray')).length; index++) 
          {
            let formValue = (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value;
            let porcentaje;
  
            if (this.tipoCliente.toLowerCase() == 'empresas') 
            {
              porcentaje = formValue.porcentaje;
              if (porcentaje != null) 
              {
                if (porcentaje.toString().indexOf(',') > -1) 
                {
                  porcentaje = porcentaje.replace(',', '.');
                }
              }
            } 
            else 
            {
              porcentaje = null;
            }
  
            if (formValue.banco.codigo.toString() != formValue.cuenta.substring(0, 4).toString()) 
            {
              countErr++;
  
              this.loading = false;
              this.numBanco = false;
              return (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(true);
            } 
            else 
            {
              (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(false);
              this.numBanco = true;
              let porc = null;
              if(this.tipoCliente == "Empresas"){
                  porc = this.entornoService.limpiarMonto((<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value.porcentaje);
              }
              if (index != (<FormArray>this.formCuentaCliente.get('cuentasArray')).length - 1) {
                cuenta = {
                  id: this.idsDeCuentasParaActualizar[index],
                  nroCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value.cuenta,
                  tipoCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value.tipocuenta,
                  porcLiq: porc
                };
              }
              else {
                let porc = null;
                if(this.tipoCliente == "Empresas"){
                    porc = this.entornoService.limpiarMonto((<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value.porcentaje);
                }
                cuenta = {
                  id: null,
                  nroCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value.cuenta,
                  tipoCuenta: (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value.tipocuenta,
                  porcLiq: porc
                };
  
              }
              cuentas.push(cuenta);
            }
          }
          if (cuentas.length > 0 && countErr == 0 && cuentas.length == (<FormArray>this.formCuentaCliente.get('cuentasArray')).length) {
            // if(!this.cuentasEnviadasAlApi)
            // {
              this.authService.actualizarCuentasRegistradasPreviamente(this.afiliacion.identificacion, cuentas).subscribe((res: any) => {
                this.loading = false;
                if (res && res.errorMessage != undefined) {
                  this.errorMessage = res.errorMessage;
                }
                else {
                  // this.cuentasEnviadasAlApi = true;
                  this.nextSteep();
                }
              }, error => {
                this.loading = false;
                let e = responseErrorParser(error);
                this.errorMessage = e.errorMessage;
              });
            // }
            // else
            // {
            //   console.log("ctas no eviadas al api")
            // }
          }
        }
      }
    }
 

  }

  cargarFormularioRecaudos() {
    //
    this.subjectsService.pdfViewerActive.subscribe(res => {
      this.pdfViewerActive = res;
    });
    //this.tipoCliente.toLowerCase()
    if(!this.formDeRecaudosCargados)
    {
      this.authService.listaTipoRecaudos(this.tipoCliente.toLowerCase()).subscribe((res: any) => {
        if (res.errorMessage) {
          this.errorMessage = res.errorMessage;
        }
        else if (res && res.length > 0) {
          this.formDeRecaudosCargados = true;
          this.recaudosMostrar = res;
        
          const jobGroup: FormGroup = new FormGroup({});
          for (let i = 0; i < this.recaudosMostrar.length; i++) {
            //this.afiliacion.identificacion.substring(0,1)
            let  success = {recaudo:null, pdf: null, subido:null, archivo:null };
  
            if(this.recaudosMostrar[i].nombre == 'PASAPORTE' && this.afiliacion.identificacion.substring(0,1) == 'E')
            {
              this.recaudosMostrar[i].obligatorio = 1;
              this.recaudos.push(this.recaudosMostrar[i]);
  
                      success.recaudo=  this.recaudosMostrar[i].id;
                      success.pdf=  false;
                      success.subido= false;
                      success.archivo= null;
                      this.recaudosObli.push(success);
            }
            else
            {
              if(this.recaudosMostrar[i].nombre != 'PASAPORTE')
              {
                this.recaudos.push(this.recaudosMostrar[i]);
  
                      success.recaudo=  this.recaudosMostrar[i].id;
                      success.pdf=  false;
                      success.subido= false;
                      success.archivo= null;
                      this.recaudosObli.push(success);
              }
            }
          }
    
          for(let index = 0; index< this.recaudos.length; index++)
          {
            if(this.recaudos[index].obligatorio == 1)
            {
              this.recaudosObligatorios += 1;
            }
            this.recaudos[index].archivoAgregado = false;
            const element = this.recaudos[index];
            let obj = element.nombre;
            let ad = 'adjunto' + index.toString();
            let file = 'file' + index.toString();
            this.fileInput[obj] = element.nombre; //para la construcción del json del fileInput
            this.fileInput[file] = null; //para asociar el archivo al tipo de recaudo           
            this.adjunto[ad] = false; //para habilitar el botón de guardar dependiendo de su tipo de recaudo
            const control: FormControl = new FormControl('');
            this.formRecaudos.addControl(element.nombre, control); // nombre del tipo de recaudo
            this.tamanoArchivos = this.recaudos[index].tamanoMaxKB;
          }
  
          this.loading = false;
        }
        else {
          this.loading = false;
        }
      }, error => {
        let e = responseErrorParser(error);
        this.errorMessage = e.errorMessage;
      });
    }
    
  }

  buscarCuentasPreviasRegistro() {
//
    if (this.benef == true || this.afi == true) {
      this.authService.cuentasRegistradasPreviamente(this.afiliacion.identificacion).subscribe((res: any) => {
        let cuentasPrevias: any[] = res;

        if (cuentasPrevias.length > 0) {
          this.dialog.open(ConfirmationDialogComponent,
            {
              disableClose: true ,
              data:
              {
                title: 'Afiliación',
                message: 'Usted ya posee cuentas registradas. Probablemente de un intento de afiliación sin culminar o anterior existencia en el sistema. ¿Desea recuperar las cuentas?'
              },
              width: '400px'
            })
            .afterClosed().subscribe((option: string) => {
              if (option === 'Si') {
                this.unique =true;
                this.actualizarCuentas = true;
                this.errorMessage = null;
                this.errorRifExiste = null;
    

                for (let index = 0; index < cuentasPrevias.length; index++) {
                  let val = JSON.stringify(cuentasPrevias[index]);
                  let json = JSON.parse(val);
                  let indexBanco: number = this.bancos.findIndex(banco => banco.codigo === json.nroCuenta.substring(0, 4));
                  this.idsDeCuentasParaActualizar.push(json.id);
                
          
                  if (this.tipoCliente.toLowerCase() == 'empresas') {
                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['banco'].setValue(this.bancos[indexBanco]);
                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['cuenta'].setValue(json.nroCuenta);
                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['tipocuenta'].setValue(json.tipoCuenta);
                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['porcentaje'].setValue(this.entornoService.pipeDecimalBigNumber(json.porcLiq));
                  
                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(false);

                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorPorc'].setValue(false);
                  }
                  else {
                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['banco'].setValue(this.bancos[indexBanco]);
                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['cuenta'].setValue(json.nroCuenta);
                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['tipocuenta'].setValue(json.tipoCuenta);
              
                    (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(false);

                                   }
              
                  if (index < cuentasPrevias.length - 1 && this.countAcept < cuentasPrevias.length) {
                    this.countAcept++;
                    this.ampliarFormularioParaCuentasPrevias();
                  }
                }
             
             
              }
              else {
                this.actualizarCuentas = false;
                for (let index = 0; index < cuentasPrevias.length; index++) {
                  let val = JSON.stringify(cuentasPrevias[index]);
                  let json = JSON.parse(val);
                  let indexBanco: number = this.bancos.findIndex(banco => banco.codigo === json.nroCuenta.substring(0, 4));

                  (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['banco'].setValue(this.bancos[indexBanco]);
                }
              }
            });
        }

      });
    }

  }

  enviarDatosBancarios(cuentas: any, identificacion: string) {
   
    this.authService.enviarCuenta(cuentas, identificacion).subscribe((res: any) => {
      this.loading = false;
      if (res && res.errorMessage != undefined) {
        this.errorMessage = res.errorMessage;
      }
      else {
        this.nextSteep();
      }
    }, error => {
      this.loading = false;
      let e = responseErrorParser(error);
      this.errorMessage = e.errorMessage;
    });
  }

  validarPorcentajeMayorACien(index:number)
  {
    let formValue = (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value;
    let porcentajeLimpio = this.entornoService.limpiarMonto(formValue.porcentaje);
    if(parseInt(porcentajeLimpio) > 100)
    {
      return (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['porcentaje'].setValue(this.entornoService.pipeDecimalBigNumber(100));
    }
    console.log("valor final=> ",this.entornoService.limpiarMonto(formValue.porcentaje))
  }

  validarPorcentajeCuentas(): boolean {
    let exito = false;
    let sumaPorcentajes: number = 0;
    for (let index = 0; index < (<FormArray>this.formCuentaCliente.get('cuentasArray')).length; index++) {
      let campoPorcentaje = (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value;
      if (typeof (campoPorcentaje.porcentaje) == 'string' && campoPorcentaje.porcentaje.includes(',')) {
        //campoPorcentaje = campoPorcentaje.porcentaje.replace(',', ".");
        campoPorcentaje.porcentaje = this.entornoService.limpiarMonto(campoPorcentaje.porcentaje);
      }
      sumaPorcentajes = sumaPorcentajes + Number(campoPorcentaje.porcentaje);//Number(campoPorcentaje.porcentaje);
    }
    if (sumaPorcentajes == 100) {
      exito = true;
    }
    else {
      exito = false;
    }
    return exito;
  }
  onDate(event) {
    if (this.tipoCliente.toLowerCase() == 'personas') {

      let anioLimite = (new Date().getFullYear()) - this.edadLimite;
      if (Math.sign(anioLimite - Number(moment(this.convertFecha(event.value), "YYYY-MM-DD").year())) == -1) {
        this.edadFallo = true;
        this.errorMessage = "La fecha de nacimiento no corresponde con la edad límite el cual debe ser de " + this.edadLimite + " años";

      } else {
        this.edadFallo = false;
        this.errorMessage = null;
      }
    }
  }

  seleccionDeOpciones(inst: any, nombre: string, index: number, recaudo: any) {

    this.dialog.open(OrigenCargaRecaudoDialogComponent,
      {
        disableClose: true ,
        data:
        {
          title: 'Carga de Recaudos',
          message: 'Seleccione desde dónde desea subir su documento.',
          camaraPresente: this.camaraDisponible
        },
        width: '400px'
      }).afterClosed().subscribe((option: string) => {
        if (option === 'Computador') {
          this.uploadFile(inst);
        }
        if (option === 'Foto') {
          this.fotoTomada = true;
          this.dialog.open(TomarFotoRecaudoDialogComponent,
            {
              data:
              {
                title: 'Capturar documento.',
                message: 'Centre su documento en el recuadro de la imagen y oprima capturar.',
                height: '728px'
              },
              width: '1024px'
            }).afterClosed().subscribe((option: string) => {
              if (option.split('-')[0] == 'img') {
                console.log("SI ES IMG")
                let imgUrl = option.split('-')[1];
                let fileFormat: string = 'image/jpeg';
                let fileName = nombre + "_" + this.afiliacion.identificacion + ".jpg";
                var mimeString = imgUrl.split(',')[0].split(':')[1].split(';')[0];
                const arr = imgUrl.split(',');
                const bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
                }
                recaudo.imgUrl = imgUrl
                let file = new File([u8arr], fileName, { type: fileFormat });
                this.onFileChange([file], nombre, null, index, recaudo, true, imgUrl);
              }
              if (option.split('-')[0] == 'err') {
                let error = option.split('-')[1];
                this.dialog.open(ConfirmationDialogComponent,
                  {
                    disableClose: true ,
                    data:
                    {
                      title: 'Error',
                      message: 'No posee una camara conectada. Intente cargar la imagen desde su ordenador'
                    },
                    width: '400px'
                  }).afterClosed().subscribe((option: string) => {
                    this.camaraDisponible = false;
                    if (option == 'Si') {
                    }
                  });
              }
            });
        }
      });
  }

  uploadFile(inst: any) {
    
   //document.getElementById(inst).setAttribute("value", null)
    //this.myfile.nativeElement.value = "";

    document.getElementById(inst).click();
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
  }

  cargarRecaudo(nombre: string, index: number)
  {
    this.authService.cargarArchivoAfiliacion(nombre, this.fileInput['file' + index], this.afiliacion.identificacion, this.tipoCliente.toLowerCase()).subscribe((res: any) => 
    {
      if (res.errorMessage)
      {
        this.loading = false;
        this.errorMessage = res.errorMessage;
      }
      else
      {
        this.errorMessage = null;
        this.loading = false;
        this.recaudos[index].archivoAgregado = true;
        this.recaudosObli[index].subido = true;
        this.dialog.open(ConfirmationDialogComponent,
          {
            disableClose: true ,
            data:
            {
              title: 'Recaudos',
              message: 'El archivo ha sido cargado exitosamente.'
            },
            width: '300px'
          }).afterClosed().subscribe((option: string) => {
            if (option === 'Aceptar') {
              this.formRecaudos.controls[nombre].setValue(null);
              this.adjunto['adjunto' + index] = false;
              this.loading = false;
            }
          });          
        this.validarRecaudos();
      }
    }, error => {      
      this.loading = false;
      let e = responseErrorParser(error);
      this.dialog.open(ConfirmationDialogComponent,{
        data:
            {
              disableClose: true ,
              title: 'Error',
              message: e.errorMessage
            },
            width: '300px'
          }).afterClosed().subscribe((option: string) => {
            this.loading = false;
            this.formRecaudos.controls[nombre].setValue(null);
            this.recaudos[index].archivo = null;
            this.filesArray[index] = null;
            if (option === 'Aceptar') {
            }
          });
      this.errorMessage = e.errorMessage;
    });
  }

  onFileChange(files: File[], control: string, placeholder: string, index: number, recaudo: any, isImg?: boolean, fotoTomadaUrl?:any) {
    this.archivoPermitido=true;
    if (files.length > 0) 
    {
      let file = files[0];
      // this.filesArray[index] = file;
      const division = file.name.split('.');
      const type = division[1];//division.length - 1
      let tamfile = Number(file.size)/Number(recaudo.tamanoMaxKB);
      if (type.toLocaleLowerCase() == 'pdf' || type.toLocaleLowerCase() == 'jpg') 
      {

        if(tamfile > recaudo.tamanoMaxKB){
          this.dialog.open(ErrorRecaudoDialogComponent,
            {
              disableClose: true ,
              data:
              {
                title: 'Error',
                message: "El tamaño del archivo excede el valor permitido ("+recaudo.tamanoMaxKB+" KB)"
              },
              width: '400px'
            }).afterClosed().subscribe((option: string) => {
              if (option == 'Si') {
              }
            });
  
        }
        
        else if(tamfile <= 0){
          this.dialog.open(ErrorRecaudoDialogComponent,
            {
              disableClose: true ,
              data:
              {
                title: 'Error',
                message: "El tamaño del archivo debe ser mayor a 0KB y no exceder del valor permitido ("+recaudo.tamanoMaxKB+" KB)"
              },
              width: '400px'
            }).afterClosed().subscribe((option: string) => {
              if (option == 'Si') {
              }
            });
  
        }else{
          this.dialog.open(ConfirmationDialogComponent,
            {
              disableClose: true ,
              data:
              {
                title: 'Cargar Documento',
                message: '¿Está seguro de que desea adjuntar el archivo ' + file.name + '?'
              },
              width: '500px'
            }).afterClosed().subscribe((option: string) => {
              if (option === 'Si') {
                  this.loading = true;
                  this.filesArray[index] = file;
                  const division = file.name.split('.');
                  const type = division[division.length - 1];
                  if (type.toLocaleLowerCase() == 'pdf' || type.toLocaleLowerCase() == 'jpg') 
                  {
                    if(type.toLocaleLowerCase() == 'pdf'){
                      this.recaudosObli[index].pdf = true;
                    }else{
                      this.recaudosObli[index].pdf = false;
                    }
                    
                    this.formRecaudos.controls[control].setValue(file.name);
                    this.comprobante = file;
                    this.adjunto['adjunto' + index] = true;
                    this.fileInput['file' + index] = file;
                    this.changeFile(file).then((base64: string): any => {
                      let archivo = 
                      {
                        base64DataFile: base64
                      };
                      //console.log("arregloBase64 => ",this.arregloBase64)
                      if(this.arregloBase64[index] == null)
                      {
                        this.archivosIguales = false; 
                        this.fileBlob = new Blob([base64], { type });
                        this.mostrarRecaudo(index, recaudo, isImg, fotoTomadaUrl);
                        this.arregloBase64[index]=archivo;
                        this.validarRecaudosRepetidos(index);
                      }
                      else
                      {
                        this.archivoYaSubido = false;
                        this.validarRecaudosRepetidos(index, archivo);
                        //console.log("mostrar archivo nuevo")
                        this.fileBlob = new Blob([base64], { type });
                        this.mostrarRecaudo(index, recaudo, isImg, fotoTomadaUrl);
                      }
                      if(!this.archivosIguales)
                      {
                        this.cargarRecaudo(control, index);
                      }
                      else
                      {
                        this.dialog.open(ErrorRecaudoDialogComponent,
                          {
                            disableClose: true ,
                            data:
                            {
                              title: 'Error al Cargar Documento',
                              message: 'El archivo ' + file.name + ' ya fue cargado anteriormente'
                            },
                            width: '500px'
                          }).afterClosed().subscribe((option: string) => {
                            if (option == 'Si')
                            {
                              this.loading = false;

                              if(!this.archivoYaSubido)
                              {
                                //console.log("eliminar lo ultimo que se intentó subir")                              
                                this.archivosIguales = false; 
                                this.formRecaudos.controls[control].setValue(null);
                                this.recaudos[index].archivo = null;
                                this.filesArray[index] = null;
                                this.arregloBase64.splice(index, 1);
                              }
                            }
                          });
                      }
                    });
                  }                  
                }
                else
                {
                  this.formRecaudos.controls['archivo'].setValue(null);
                  this.recaudos[index].archivo = null;
                  this.filesArray[index] = null;
                }
            });
        }
      }
      else
      {
        this.dialog.open(ErrorRecaudoDialogComponent,
          {
            disableClose: true ,
            data:
            {
              title: 'Error',
              message: 'El archivo debe tener la extension: .pdf o .jpg'
            },
            width: '400px'
          }).afterClosed().subscribe((option: string) => {
            if (option == 'Si') {
              this.formRecaudos.controls[control].setValue(null);
              this.recaudos[index].archivo = null;
              this.filesArray[index] = null;
              this.archivoPermitido = false;
            }
          });    
      }     
    }
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  mostrarRecaudo(index: number, recaudo: any, isImg?:boolean, fotoTomadaUrl?:any) {
    let dataType = this.filesArray[index].type;
    let binaryData = [];
    binaryData.push(this.filesArray[index]);

    let url = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    if(this.typeFile == false){
      if(!isImg)
      {
        var reader = new FileReader();
        this.imagePath = this.filesArray;
        reader.readAsDataURL(this.filesArray[index]); 
        reader.onload = (_event) => {
          this.recaudosObli[index].archivo = reader.result;
        } 
      }  
      else
      {
        this.recaudosObli[index].archivo = fotoTomadaUrl;
      }
    }
    if(isImg)
    {
      //let objectURL = 'data:image/jpeg;base64,'+url;
      //this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(fotoTomadaUrl);
      this.recaudosObli[index].archivo = fotoTomadaUrl;
      recaudo.img = this.recaudosObli[index].archivo;
      recaudo.archivo = fotoTomadaUrl; 
    }
    else
    {
      recaudo.archivo = url
      recaudo.zoom = this.zoom;
      recaudo.img = null;
    }
    this.fileUrls = url;
  }

  
  //comparacion de archivos
  validarRecaudosRepetidos(index: number, archivoReemplazo?:any)
  {
    if(archivoReemplazo == null || archivoReemplazo == undefined)
    {
      let nuevoArchivoBase64 = this.arregloBase64[index].base64DataFile;
      for(let i = 0; i < this.arregloBase64.length; i++)
      {
        if(i != index)
        {
          if(this.arregloBase64[i] != null)
          {
            let archivoPrevioBase64 = this.arregloBase64[i].base64DataFile;
            if(JSON.stringify(archivoPrevioBase64) === JSON.stringify(nuevoArchivoBase64))
            {
              //console.log("archivos iguales")
              this.archivosIguales = true;
            }
            else
            {
              //console.log("archivos diferentes")
            }
          }
        }
      }
    }
    else
    {
      let archivoDeReemplazo = archivoReemplazo.base64DataFile;
      let archivoPrevio = this.arregloBase64[index].base64DataFile;
      if(JSON.stringify(archivoPrevio) === JSON.stringify(archivoDeReemplazo))
      {
        //console.log("archivo ya subido en esa posicion")
        this.archivoYaSubido = true;
        this.archivosIguales = true;
      }
      else
      {
        /*
        El archivo que se quiere subir no es igual al que ya se había subido antes
        Se valida que este nuevo archivo no esté en otro recaudo
        */
        for(let i = 0; i< this.arregloBase64.length; i++)
        {
          if(i != index)
          {
            if(this.arregloBase64[i] != null)
            {
              let archivoPrevioBase64 = this.arregloBase64[i].base64DataFile;
              if(JSON.stringify(archivoPrevioBase64) === JSON.stringify(archivoDeReemplazo))
              {
                //console.log("archivo duplicado en otro recaudo")
                this.archivosIguales = true;
                this.archivoYaSubido = true;
              }
            }
          }
        }
        if(!this.archivoYaSubido && !this.archivosIguales)
        {
          this.arregloBase64[index]=archivoReemplazo;
          //console.log("archivos no iguales se puede subir")
        }
      }
    }
  }

  cambiarZoomArchivo(accion: string, recaudo: any) {
    if (accion == 'Ampliar') {
      recaudo.zoom = recaudo.zoom + 0.5;
    }
    else {
      recaudo.zoom = recaudo.zoom - 0.5;
    }
  }

  validarRecaudos() {
    let cantArchivosAgregados: number = 0;
    for (let index = 0; index < this.recaudos.length; index++) 
    {
      if (this.recaudos[index].archivoAgregado)
      {
        cantArchivosAgregados++;
      }
    }
    if (cantArchivosAgregados == this.recaudosObligatorios)
    {
      this.recaudosCompletos = true;
    }
  }

  enviarDireccion() {
    this.loading = true;
    this.errorMessage = null;
    

    let formValue = this.formDireccion.value;
    let direccion;
    formValue.calleAv = this.entornoService.limpiarCampo(formValue.calleAv,"texto-espacio");
    this.formDireccion.controls['calleAv'].setValue(formValue.calleAv);
    formValue.casaEdificio=this.entornoService.limpiarCampo(formValue.casaEdificio,"texto-espacio");
    this.formDireccion.controls['casaEdificio'].setValue(formValue.casaEdificio);
    formValue.piso=this.entornoService.limpiarCampo(formValue.piso,"texto-espacio");
    this.formDireccion.controls['piso'].setValue(formValue.piso);

    if(this.tipoCliente == 'Personas'){
      formValue.ptoReferencia=this.entornoService.limpiarCampo(formValue.ptoReferencia,"texto-espacio");
      this.formDireccion.controls['ptoReferencia'].setValue(formValue.ptoReferencia);

    }

    this.errorMessage = "";
    if(formValue.calleAv !=null && 
      (formValue.calleAv.substring(0,1)==" " || formValue.calleAv.substring(formValue.calleAv.length-1,formValue.calleAv.length)==" ")){
        this.loading = false;
        this.errorMessage = "La calle no debe contener espacios al principio ni al final, tampoco más de un espacio entre palabras. Por favor elimine el espacio del final";
    }else if(formValue.casaEdificio !=null && 
      (formValue.casaEdificio.substring(0,1)==" " || formValue.casaEdificio.substring(formValue.casaEdificio.length-1,formValue.casaEdificio.length)==" ")){
        this.loading = false;
        this.errorMessage = "La casa o edificio no debe contener espacios al principio ni al final, tampoco más de un espacio entre palabras. Por favor elimine el espacio del final";
    }else if(formValue.piso !=null && 
      (formValue.piso.substring(0,1)==" " || formValue.piso.substring(formValue.piso.length-1,formValue.piso.length)==" ")){
        this.loading = false;

        this.errorMessage = "El piso no debe contener espacios al principio ni al final, tampoco más de un espacio entre palabras. Por favor elimine el espacio del final";
    }else if(this.tipoCliente == 'Personas' && formValue.ptoReferencia !=null && 
    (formValue.ptoReferencia.substring(0,1)==" " || formValue.ptoReferencia.substring(formValue.ptoReferencia.length-1,formValue.ptoReferencia.length)==" ")){
      this.loading = false;

      this.errorMessage = "El punto de referencia no debe contener espacios al principio ni al final, tampoco más de un espacio entre palabras. Por favor elimine el espacio del final";
    }else{


      if (formValue.subactividad != null) {
        if (formValue.subactividad.id_padre == formValue.actividadeconomica.id) {
          formValue.actividadeconomica = formValue.subactividad;
        }
      }
  
  
      let monto = formValue.monto;
  
      if(monto != null){
        formValue.monto = this.entornoService.limpiarMonto(monto);
        this.formDireccion.controls['monto'].setValue(formValue.monto);

      }
  
  
      if (this.tipoCliente.toLowerCase() == 'personas') {
  
  
        direccion = {
          region: formValue.region.id,
          estado: formValue.estado.id,
          ciudad: formValue.ciudad.id,
          municipio: formValue.municipio.id,
          parroquia: formValue.parroquia.id,
          sector: formValue.sector.id,
          pto_referencia: formValue.ptoReferencia,
          sexo: formValue.sexo.id,
          profesion: formValue.profesion.id,
          edo_civil: formValue.edocivil.id,
          monto_mov: formValue.monto,
          date: this.convertFecha(formValue.fecha),
          agente_retencion: null,
          contribuyente: null,
          calle: formValue.calleAv,
          casa_edificio: formValue.casaEdificio,
          piso: formValue.piso,
          id_act_ec: null
        }
  
      } else {
        direccion = {
          region: formValue.region.id,
          estado: formValue.estado.id,
          ciudad: formValue.ciudad.id,
          municipio: formValue.municipio.id,
          parroquia: formValue.parroquia.id,
          sector: formValue.sector.id,
          pto_referencia: null,
          sexo: null,
          profesion: null,
          monto_mov: formValue.monto,
          date: this.convertFecha(formValue.fecha),
          agente_retencion: formValue.agente,
          contribuyente: formValue.contribuyente,
          calle: formValue.calleAv,
          casa_edificio: formValue.casaEdificio,
          piso: formValue.piso,
          id_act_ec: formValue.actividadeconomica.id
        }
      }
  
  
  
      this.authService.enviarDireccion(direccion, this.afiliacion.identificacion + "").subscribe((res: any) => {
  
  
        this.loading = false;
        if (res && res.errorMessage != undefined) {
          this.errorMessage = res.errorMessage;
        }
        else {
          this.authService.bancos().subscribe((res: any) => {
  
  
            this.loading = false;
            if (res && res.errorMessage != undefined) {
              this.errorMessage = res.errorMessage;
            }
            else {
              this.bancos = res;
              this.buscarCuentasPreviasRegistro();
  
  
              if (this.suple == true && this.benef == false) {
                this.steep++;
                this.nextSteep();
              }
              if (this.benef == true || this.afi == true) {
                this.nextSteep();
              }
  
            }
          }, error => {
            this.loading = false;
            let e = responseErrorParser(error);
            this.errorMessage = e.errorMessage;
  
          });
  
        }
      }, error => {
        this.loading = false;
        let e = responseErrorParser(error);
        this.errorMessage = e.errorMessage;
  
      });

    }


  }

  /**
   * Método que se encarga de registrar los datos en la tabla de afiliaciones (Para el control)
   * Y luego ejecuta el envio de la OTP en caso de que el registro sea correcto
   */
  afiliar(tipoCliente: string) {
    this.loading = true;
    this.errorMessage = null;
    this.errorRifExiste = null;

    let formValue = this.form.value;
    //Se Setea la variable global para los datos de afiliacion
    //Ya que seran usados en lo largo del proceso
    let documento = formValue.tipoDocumento + formValue.numeroDocumento;
    if (this.tipoCliente.toLowerCase() == 'personas') {

      formValue.registroMercantil = "";
    }else{
      this.form.controls['registroMercantil'].setValue(this.entornoService.limpiarCampo(this.form.controls['registroMercantil'].value, "numeros"));

      formValue.registroMercantil = this.entornoService.limpiarCampo(formValue.registroMercantil,"numeros");

    }
    this.afiliacion = {
      identificacion: documento,
      nombreCompleto: formValue.nombre,
      correo: formValue.correo,
      numeroTelefono: formValue.codigoTelefono + "" + formValue.telefono,
      registroMercantil: formValue.registroMercantil
    }

    this.authService.crearAfiliacion(this.afiliacion, tipoCliente).subscribe((res: any) => {


      
      if (res && res.errorMessage != undefined) {
        this.errorMessage = res.errorMessage;
        this.loading = false;
      }
      else {
        this.edadLimite = res.edad;
        this.afiliacion.numeroTelefono = res.telefonos[0].id;
        this.enviarOTP();
      }
    }, error => {
      this.loading = false;
      let e = responseErrorParser(error);
      this.errorMessage = e.errorMessage;

    });

  }

  /**
   * Método para enviar la OTP
   */
  enviarOTP(isResend?: boolean) {
    this.loading = true;
    this.errorMessage = null;
    this.errorRifExiste = null;
    this.isSentOtp = true;
    
    this.authService.sendOTP(this.afiliacion.identificacion, this.afiliacion.numeroTelefono).subscribe((res: any) => {
    
      if (res && res.errorMessage != undefined) {
        this.errorMessage = res.errorMessage;
        this.loading = false;
      }
      else {
        this.loading = false;
        if (!isResend) {
          this.createFormUsuario();
          this.nextSteep();
        }
        this.formClave.reset();
        this.contador = 300;
        this.iniciarCuenta();
      }
    }, error => {
      this.loading = false;
      let e = responseErrorParser(error);
      this.errorMessage = e.errorMessage;
    });
  }

  iniciarCuenta() {
    this.cuentaRegresiva = Observable.timer(0, 1000).take(this.contador)
      .map(() => this.contador = this.contador == 0 ? 0 : (this.contador % 100) == 0 ? (this.contador - 41) : (this.contador - 1));
  }

  /**
   * Metodo para validar la OTP y 
   * generar la llave de seguridad que se debe enviar en el registro del usuario
   */
  validarOTP() {
    this.loading = true;
    this.errorMessage = null;
    this.errorRifExiste = null;
    let formClaveValue = this.formClave.value;
    this.formClave.controls['clave'].setValue(this.entornoService.limpiarCampo(this.formClave.controls['clave'].value, "numeros"));

    this.authService.validarOTP(this.afiliacion.identificacion + "", formClaveValue.clave).subscribe((res: any) => {
      // this.loading = false;
      if (res.errorMessage) {
        this.errorMessage = res.errorMessage;
      }
      else if (res) {
        this.otpValidada = true;
        //Llave de validación
        this.afiliacion.codigoVerificacion = res.codigoVerificacion;
        //Se setean las preguntas de seguridad disponibles para seleccionar
        this.preguntaOptions = [];
        this.preguntaOptions = res.preguntasDesafio;

        //Se setea la cantidad de preguntas desafio que se deben seleccionar
        this.preguntasDesafioRequeridas = 2;
        this.preguntasSeleccionadas = [];
        //Estas palabras reservadas son por si hay que validarlas en el front
        //Para que el usuario no las ingrese
        this.palabrasReservadas = res.palabrasReservadas;

        //Se llena el arreglo para mostrar las preguntas segun la cantidad de preguntas desafio
        for (let index = 0; index < this.preguntasDesafioRequeridas; index++) {
          const element = {
            id: null,
            pregunta: null,
            respuesta: null,
            confirmarRespuesta: null,
            options: this.preguntaOptions,
            errorPreguntaVacia: false,
            errorMinLength: false,
            errorRepetido: false,
            errorConfirmacionMatch: false,
            errorEmpty: false
          };
          this.preguntasSeleccionadas.push(element);

        }

        for (let i = 0; i < res.cuentas.length; i++) {
          if (res.cuentas[i].idTipo == 1) {
            this.benef = true;
          } else
            if (res.cuentas[i].idTipo == 2) {
              this.suple = true;

            } else
              if (res.cuentas[i].idTipo == 3) {
                this.afi = true;
              }
        }

        this.formClave.reset();

        this.authService.regiones().subscribe((res: any) => {

          if (res.errorMessage) {
            this.loading = false;
            this.errorMessage = res.errorMessage;
          }
          else if (res) {

            this.regiones = res;

            if (this.tipoCliente.toLowerCase() == 'empresas') {

              this.authService.actividades().subscribe((res: any) => {

                if (res.errorMessage) {
                  this.loading = false;
                  this.errorMessage = res.errorMessage;
                }
                else if (res) {

                  this.actividadestodas = res;
                  this.actividades = res.filter(res => res.id_padre == 0);
                  this.loading = false;
                  this.nextSteep();
                }
              }, error => {
                this.loading = false;
                let e: any = responseErrorParser(error);

                this.errorMessage = e.errorMessage;

              });
            } else {
              this.loading = false;
              this.nextSteep();
            }


          }
        }, error => {
          this.loading = false;
          let e: any = responseErrorParser(error);

          this.errorMessage = e.errorMessage;

        });

      }
    }, error => {
      this.loading = false;
      let e: any = responseErrorParser(error);
      if (e.codigo == 181) {
        this.errorMessage = 'La clave temporal es incorrecta o ha expirado, solicite nueva clave temporal';
      } else {
        this.errorMessage = e.errorMessage;
      }
    });
  }

  /**
   * Metodo que valida el usuario y luego procede a registrarlo
   * en las tablas de Usuario y Afiliado
   */
  validarUsuario() {


    this.loading = true;
    this.errorMessage = null;
    this.errorRifExiste = null;
    let formUsuarioValue = this.formCrearUsuario.value;
    this.errorMessageUsuario = false;
    let preguntasSel =  this.preguntasObj;
    
    //preguntasSel.push(this.preguntaRespuestaPersonalizada);

    console.log(preguntasSel)
    //Se crea el objeto para la validacion del usuario
    let validacionObj: ValidacionUsuario = {
      usuario: this.entornoService.limpiarCampo(formUsuarioValue.usuario,"texto"),
      contrasena: this.entornoService.limpiarCampo(formUsuarioValue.contrasena,"contrasena"),
      preguntasDesafio: preguntasSel
    }
    
    //Primero validamos que los datos estén correctos
    this.authService.validarUsuario(this.afiliacion.identificacion + "", validacionObj).subscribe((res: any) => {

      if (res && res.errorMessage) {
        this.errorMessage = res.errorMessage
        this.loading = false;
       
      }
      else {
        //Procedemos a realizar el registro
        let registroObj: RegistroUsuario = {
          afiliacion: this.afiliacion,
          validacionUsuario: validacionObj
        }

        let tipo = localStorage.getItem('tipoCliente')
        let tipoPersona = tipo == 'Personas' ? 'B' : 'A'

        this.authService.registrarUsuario(registroObj, tipoPersona).subscribe((res: any) => {
          this.loading = false;
          if (res && res.errorMessage) {
            this.errorMessage = res.errorMessage;
          }
          else {
            //Finaliza el proceso de creacion

            this.nextSteep();
            this.registroMessage = 'Su usuario se ha creado exitosamente';
          }
        }, error => {
          this.loading = false;
          //this.preguntasSeleccionadas.pop();
          let e = responseErrorParser(error);
          this.errorMessage = e.errorMessage;
        });
      }
    }, error => {
      this.loading = false;
      //this.preguntasSeleccionadas.pop();
      let e: any = responseErrorParser(error);
      if (e.codigo == 183) {
        this.errorMessageUsuario = true;
        this.errorMessage = e.errorMessage;
      } else {
        this.errorMessage = e.errorMessage;
      }
    });
  }

  loggingIn() { }

  onLogin() { }

  onFormChange() {

  }

  validarMonto() {
    let monto = this.formDireccion.controls['monto'].value;

    if (monto.toString().indexOf('.') >= 0) {
      monto = monto.split(".").join("");
    }

    const format = new RegExp(/^\d{1,17}(\,?\d{0,2}|\,\d{1,2})$/gi);
    if (!format.test(monto.toString())) {

      let aux = monto;
      monto = aux
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
      return this.formDireccion.controls['monto'].setValue(monto.slice(0, -1));

    } else {


      let aux = monto;
      monto = aux
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

      return this.formDireccion.controls['monto'].setValue(monto);
    }

  }

  validarPreguntas() {
    this.loading = true;
    this.errorMessage = null;
    this.errorRifExiste = null;
    /*    private Long id_respuesta;

    private Integer id;

    private String pregunta;

    private String respuesta;

    private Boolean activo;*/
    
   
   
    if(this.preguntasObj.length == 0){
      for (var i = 0; i < this.preguntasSeleccionadas.length; i++) {
        let preguntas= {
          id: null,
          pregunta: null,
          respuesta: null,
          confirmarRespuesta: null,
          options: [],
          errorPreguntaVacia: false,
          errorMinLength: false,
          errorRepetido: false,
          errorConfirmacionMatch: false,
          errorEmpty: false
        };
        preguntas.id= this.preguntasSeleccionadas[i].id;
        preguntas.options = this.preguntasSeleccionadas[i].options;
        this.preguntasSeleccionadas[i].respuesta = this.entornoService.limpiarCampo(this.preguntasSeleccionadas[i].respuesta,"texto");
        preguntas.respuesta = this.preguntasSeleccionadas[i].respuesta;
        this.preguntasSeleccionadas[i].confirmarRespuesta = this.entornoService.limpiarCampo(this.preguntasSeleccionadas[i].confirmarRespuesta,"texto");
        this.preguntasObj.push(preguntas);
      }
    }


  
    this.preguntaRespuestaPersonalizada.pregunta = this.entornoService.limpiarCampo(this.preguntaRespuestaPersonalizada.pregunta,"texto-espacio")
    this.preguntaRespuestaPersonalizada.respuesta = this.entornoService.limpiarCampo(this.preguntaRespuestaPersonalizada.respuesta,"texto")
    this.preguntaRespuestaPersonalizada.confirmarRespuesta = this.entornoService.limpiarCampo(this.preguntaRespuestaPersonalizada.confirmarRespuesta,"texto")
   
    
    if(this.preguntaRespuestaPersonalizada.pregunta !=null && 
      (this.preguntaRespuestaPersonalizada.pregunta.substring(0,1)==" " 
      || this.preguntaRespuestaPersonalizada.pregunta.substring(this.preguntaRespuestaPersonalizada.pregunta.length-1,this.preguntaRespuestaPersonalizada.pregunta.length)==" ")){
        this.loading = false;
        this.errorMessage = "La pregunta personalizada no debe contener espacios al principio ni al final, tampoco más de un espacio entre palabras. Por favor elimine el espacio del final";
    }else{
      let pregunta= {
        id: null,
        pregunta: null,
        respuesta: null,
        confirmarRespuesta: null,
        options: [],
        errorPreguntaVacia: false,
        errorMinLength: false,
        errorRepetido: false,
        errorConfirmacionMatch: false,
        errorEmpty: false
      };
      pregunta.pregunta =  this.preguntaRespuestaPersonalizada.pregunta;
      pregunta.respuesta = this.preguntaRespuestaPersonalizada.respuesta;
      this.preguntasObj.push(pregunta);

    
     // this.preguntasSeleccionadas = preguntasObj;
     let validacionObj: ValidacionUsuario = {
        preguntasDesafio: this.preguntasObj
      }
      console.log(this.preguntasObj )
      this.authService.preValidarPreguntas(this.afiliacion.identificacion + "", validacionObj).subscribe((res: any) => {
        //this.preguntasSeleccionadas.pop();
        if (res && res.errorMessage) {
          this.errorMessage = res.errorMessage
          this.loading = false;
          
        }
        else {
          this.loading = false;
          this.createFormUsuario();
  
  
  
          this.nextSteep();
        }
      }, error => {
        //this.preguntasSeleccionadas.pop();
        this.loading = false;
        let e: any = responseErrorParser(error);
        this.errorMessage = e.errorMessage;
  
      });
    }

  
  }

  abrirPregunta(event, selectedIndex: number, selectedItem: any) {
    // Se usa para guardar el id de la pregunta antes de que cambie
    if (event == true) {
      const id = selectedItem.id
      this.preguntaAbierta = { index: selectedIndex, id: id };
    }
    else {
      this.preguntaAbierta = undefined;
    }
  }

  validarPassConfirm() {

    let pass = this.formCrearUsuario.controls['contrasena'].value;
    let confirmpass = this.formCrearUsuario.controls['contrasenaConfirm'].value;
    if(pass != null)
    {
      pass = this.entornoService.limpiarCampo(pass, "contrasena");
    }
    if(confirmpass != null)
    {
      confirmpass = this.entornoService.limpiarCampo(confirmpass, "contrasena");
    }

    if (pass != confirmpass) {
      this.confirm = true;
      this.formCrearUsuario.controls['contrasenaConfirm'].setErrors({ NoPassswordMatch: true })

    } else {
      this.confirm = false;
    }
  }
  navigateBack(): void {

    this.locationRef.back();
  }

  nextSteep() {
    if (this.steep == 5) {//5
      this.cargarFormularioRecaudos();
    }
    this.steep++;
    this.scrollTopTerms();
  }

  backSteep() {
    this.otpValidada=false;
    if (this.steep == 6 && this.benef == false && this.suple == true) {
      this.steep--;
    }
    this.steep--;
    this.scrollTopTerms();
  }

  scrollTopTerms() {
    /*
    if (this.steep == 1 && !this.aceptoTerminos) {
      let myDiv = document.getElementById('divTerminos');
      myDiv.scrollTop = 0;
    }*/
  }


   cambio(event, origen: string, index: any, accion : string, form: string){
    let formulario;
     if(form == "datos"){
        formulario = this.form;
     }else if(form == "direccion"){
       formulario = this.formDireccion;
     }

     let campo =  this.entornoService.limpiarCampo( formulario.controls[origen].value, accion);
     
    if(event != null && campo.trim().length <= 0)
    {
      formulario.controls[origen].setErrors({ required: true });
      formulario.controls[origen].markAsTouched();
    
    }
   
    if(origen == "correo" && campo.trim().length > 0 && (new RegExp(PATTERN_EMAIL).test(campo.toString())) == false){
   
      formulario.controls[origen].setErrors({ pattern: true});
    }

   }
  validarCampos(origen: string, index: any) {

    
 if (origen == 'cuenta') {

      let formValue = (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value;
     
        this.numCuenta = true;

        //this.formCuentaCliente.invalid;
    
      
        if ((<FormArray>this.formCuentaCliente.get('cuentasArray')).length > 0) {
          for (let i = 0; i < (<FormArray>this.formCuentaCliente.get('cuentasArray')).length; i++) {
              formValue.cuenta = this.entornoService.limpiarCampo((<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['cuenta'].value,"numeros" );
              let last =this.entornoService.limpiarCampo((<FormArray>this.formCuentaCliente.get('cuentasArray')).at(i).parent.controls[i].controls['cuenta'].value,"numeros" )
              if(formValue.cuenta == last
              && index != i){
                this.unique=false;
                return (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(true);
              }else{
                this.unique=true;
                return (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(false);
              }
          }
        }else{
          return (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorItem'].setValue(false);
        }

       

    } else if (origen == 'porc') {

      
      let formValue = (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).value;
   
      let porcentaje =  this.entornoService.limpiarMonto(formValue.porcentaje);

      if ((new RegExp(/^\d{1,6}(\,?\d{0,2}|\,\d{1,2})$/gi).test(porcentaje.toString())) == false) {

        this.numPor = false;

        return (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorPorc'].setValue(true);


      } else {

        this.numPor = true;
        return (<FormArray>this.formCuentaCliente.get('cuentasArray')).at(index).parent.controls[index].controls['errorPorc'].setValue(false);

      }


    


    }

  }

  rifFocusLost() {
    /* if(this.tipoCliente.toLowerCase() == 'empresas'){
     let numero = this.form.controls['numeroDocumento'].value;
     //Ponemos los 0 a la izquierda
     numero = CustomValidators.padLeft(numero, "0", 8);
     this.form.controls['numeroDocumento'].setValue(numero);
     }*/
    this.prevalidarRif();
  }

  prevalidarRif() {
   
    let id = this.form.controls['tipoDocumento'].value;
    let numero = this.form.controls['numeroDocumento'].value;

   
    if (id && numero) {
      
      if ((new RegExp(/^[0-9]+$/gi).test(numero.toString())) == false) {
        this.numeroDoc = false;
        this.rifValid = false;
    
      } else {
        this.numeroDoc = true;
        this.rifValid = true;
      }
    }else  if (numero) {
      if ((new RegExp(/^[0-9]+$/gi).test(numero.toString())) == false) {
        this.numeroDoc = false;
        this.rifValid = false;
    
      } else {
        this.numeroDoc = true;
        this.rifValid = true;
      }
    
  }




    //Validamos rif o documento de persona natural


    if (!id || !numero) {
      this.hasRif = false;
      return;
    } else {

      this.hasRif = true;
    }

    let rif = id + numero;

    if (rif.length >= 7) {

      if (this.tipoCliente.toLowerCase() == 'empresas') {
        let numeror = this.form.controls['numeroDocumento'].value;
        //Ponemos los 0 a la izquierda
        numeror = CustomValidators.padLeft(numero, "0", 9);

        rif = id + numeror;

        this.rifValid = CustomValidators.validarRif(rif);

      } else {

        this.rifValid = true;
      }

    } else {
      this.rifValid = false;
      this.hasRif = false
    }


  }

  createFormUsuario() {
    let data = [];
    data.push(this.form.controls['numeroDocumento'].value);
    data.push(this.form.controls['telefono'].value);

    this.formCrearUsuario = this.formBuilder.group({
      usuario: [null, Validators.compose(
        [
          Validators.required,
          Validators.pattern('[A-Za-z0-9]*')
        ]
      )],


      contrasena: [null, Validators.compose([Validators.required,
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(/[_$@!%*?&.-]/, { hasSpecialCharacters: true }), //falta agregar caracteres
      Validators.minLength(8),
      Validators.maxLength(16)
      ])],
      contrasenaConfirm: [null, [Validators.required]]
    },
      {
        validators: [
          CustomValidators.passwordMatchValidator,
          CustomValidators.containsRepeatCharacter,
          CustomValidators.containsRepeatCharacterUser,
          CustomValidators.containsData(data)
        ]
      });
      //this.preguntasSeleccionadas.pop();
  }


  validarPregunta(item) {
    item.errorPreguntaVacia = false;
    item.errorMinLength = false;
    item.errorRepetido = false;
    item.errorConfirmacionMatch = false;
    item.errorEmpty = false;

    //Validar que ingrese sólo números o letras
    
    if ((new RegExp(/^[A-Za-z0-9]+$/gi).test(item.respuesta)) == false) {
      let valor = item.respuesta.replace(/[^A-Za-z0-9]/g, '')
      if(valor != null)
      {
        valor = this.entornoService.limpiarCampo(valor, "texto");
      }
      item.respuesta = valor;
    }

    if ((new RegExp(/^[A-Za-z0-9]+$/gi).test(item.confirmarRespuesta)) == false) {
      let valor = item.confirmarRespuesta.replace(/[^A-Za-z0-9]/g, '')
      if(valor != null)
      {
        valor = this.entornoService.limpiarCampo(valor, "texto");
      }
      item.confirmarRespuesta = valor;
    }
    //sanitizamos la respuesta
    item.respuesta = item.respuesta ? item.respuesta.trim().replace(" ", "").toLowerCase() : '';
    item.confirmarRespuesta = item.confirmarRespuesta ? item.confirmarRespuesta.trim().replace(" ", "").toLowerCase() : '';

  

    //Validamos que se seleccione una pregunta
    if (!item.id) {
      item.errorPreguntaVacia = true;
    }

    if (item.respuesta) {

      //Validamos que la respuesta tenga mínimo 4 caracteres
      if (!item.respuesta || item.respuesta.length < 4) {
        item.errorMinLength = true;
      }

      let cont = 0;
      //Buscamos que no esté repetida
      for (let index = 0; index < this.preguntasSeleccionadas.length; index++) {
        const element = this.preguntasSeleccionadas[index];
        if (element.respuesta && (element.respuesta == item.respuesta)) {
          cont++;
          if (cont > 1) {
            item.errorRepetido = true;
            break;
          }
        }
      }

      //Validamos que coincidan con el confirmar
      if (item.confirmarRespuesta != item.respuesta) {
        item.errorConfirmacionMatch = true;
      }

    } else {
      item.errorEmpty = true;
    }
    this.validarFormPregunta();
  }

  validarPreguntaP(item) {



    this.preguntaRespuestaPersonalizada.errorPreguntaVacia = false;
    this.preguntaRespuestaPersonalizada.errorPreguntaIncorreta = false;
    this.preguntaRespuestaPersonalizada.errorRespuestaIncorrecta = false;
    this.preguntaRespuestaPersonalizada.errorMinLength = false;
    this.preguntaRespuestaPersonalizada.errorRepetido = false;
    this.preguntaRespuestaPersonalizada.errorConfirmacionMatch = false;
    this.preguntaRespuestaPersonalizada.errorEmpty = false;
    this.preguntasInvalid = false;

       //Validar que ingrese sólo números o letras
    
       if ((new RegExp(/^[A-Za-z0-9]+$/gi).test(this.preguntaRespuestaPersonalizada.respuesta)) == false) {
        let valor = this.preguntaRespuestaPersonalizada.respuesta.replace(/[^A-Za-z0-9]/g, '')
        if(valor != null)
        {
          valor = this.entornoService.limpiarCampo(valor, "texto");
        }
        this.preguntaRespuestaPersonalizada.respuesta = valor;
      }
  
      if ((new RegExp(/^[A-Za-z0-9]+$/gi).test(this.preguntaRespuestaPersonalizada.confirmarRespuesta)) == false) {
        let valor = this.preguntaRespuestaPersonalizada.confirmarRespuesta.replace(/[^A-Za-z0-9]/g, '')
        if(valor != null)
        {
          valor = this.entornoService.limpiarCampo(valor, "texto");
        }
        this.preguntaRespuestaPersonalizada.confirmarRespuesta = valor;
      }

    //sanitizamos la respuesta
    this.preguntaRespuestaPersonalizada.respuesta = this.preguntaRespuestaPersonalizada.respuesta ? this.entornoService.limpiarCampo(this.preguntaRespuestaPersonalizada.respuesta.trim().replace(" ", "").toLowerCase(),"texto") : '';
    this.preguntaRespuestaPersonalizada.confirmarRespuesta = this.preguntaRespuestaPersonalizada.confirmarRespuesta ? this.entornoService.limpiarCampo(this.preguntaRespuestaPersonalizada.confirmarRespuesta.trim().replace(" ", "").toLowerCase(),"texto") : '';



    //Validamos que se ingrese
    if (!this.preguntaRespuestaPersonalizada.pregunta) {
      this.preguntaRespuestaPersonalizada.errorPreguntaVacia = true;
    }

    //Se valida que no tenga caracteres especiales
    /* if(this.preguntaRespuestaPersonalizada.pregunta && (new RegExp(/^[a-z0-9]+$/gi).test(this.preguntaRespuestaPersonalizada.pregunta)) == false){
       this.preguntaRespuestaPersonalizada.errorPreguntaIncorreta = true;
     }*/

    if (this.preguntaRespuestaPersonalizada.respuesta) {

      //Validamos que la respuesta tenga mínimo 4 caracteres
      if (!this.preguntaRespuestaPersonalizada.respuesta || this.preguntaRespuestaPersonalizada.respuesta.length < 4) {
        this.preguntaRespuestaPersonalizada.errorMinLength = true;
      }

      //Se valida que no tenga caracteres especiales
      if ((new RegExp(/^[a-z0-9]+$/gi).test(this.preguntaRespuestaPersonalizada.respuesta)) == false) {
        this.preguntaRespuestaPersonalizada.errorRespuestaIncorrecta = true;
      }


      let cont = 0;
      //Buscamos que no esté repetida


      if (this.preguntaRespuestaPersonalizada.respuesta && (this.preguntaRespuestaPersonalizada.respuesta == item.respuesta)) {
        cont++;
        if (cont > 1) {
          this.preguntaRespuestaPersonalizada.errorRepetido = true;

        }
      }


      //Validamos que coincidan con el confirmar
      if (this.preguntaRespuestaPersonalizada.confirmarRespuesta != this.preguntaRespuestaPersonalizada.respuesta) {
        this.preguntaRespuestaPersonalizada.errorConfirmacionMatch = true;
      }

    } else {
      this.preguntaRespuestaPersonalizada.errorEmpty = true;
    }

    if (this.preguntaRespuestaPersonalizada.errorConfirmacionMatch || this.preguntaRespuestaPersonalizada.errorEmpty || this.preguntaRespuestaPersonalizada.errorMinLength || this.preguntaRespuestaPersonalizada.errorPreguntaVacia || this.preguntaRespuestaPersonalizada.errorRepetido || !this.preguntaRespuestaPersonalizada.respuesta || this.preguntaRespuestaPersonalizada.respuesta.length == 0) {
      this.preguntasInvalid = true;

    }
  }

  validarFormPregunta() {
    this.preguntasInvalid = false;
    for (let index = 0; index < this.preguntasSeleccionadas.length; index++) {
      const element = this.preguntasSeleccionadas[index];
      if (element.errorConfirmacionMatch || element.errorEmpty || element.errorMinLength || element.errorPreguntaVacia || element.errorRepetido || !element.respuesta || element.respuesta.length == 0) {
        this.preguntasInvalid = true;
        break;
      }
    }
  }


  goToLogin() {
    this.router.navigate(['/']);
  }

  sanitizarUsuario(event) {
    let usuario = event.target.value;
    if(usuario != null)
    {
      usuario = this.entornoService.limpiarCampo(usuario, "texto")
    }
    if ((new RegExp(/^[A-Za-z0-9]+$/gi).test(usuario)) == false) {
      let valor = usuario.replace(/[^A-Za-z0-9]/g, '')
      event.target.value = valor;
    }


   
 
  }

  selectactividad() {

    let actividad = this.formDireccion.controls['actividadeconomica'].value.id;
    this.subactividades = this.actividadestodas.filter(res => res.id_padre == actividad);

    if (this.subactividades.length == 0) {
      this.actividad = false;
      this.formDireccion.controls['subactividad'].clearValidators();
      this.formDireccion.controls['subactividad'].setValue(null);
    } else {
      this.actividad = true;
      this.formDireccion.controls['subactividad'].setValue(null);
      this.formDireccion.controls['subactividad'].setValidators([Validators.required]);

    }



  }
  selectsubactividad() {
    this.loading = false;
    this.actividad = false;
  }

  region() {


    this.mostrar = true;
    this.estados = this.formDireccion.controls['region'].value.estados;
    this.mostrarC = false;
    this.mostrarM = false;
    this.mostrarP = false;
    this.mostrarZ = false;
    this.mostrarR = false;
    this.mostrarZP = false;

  }

  selectestado() {

    let estado = this.formDireccion.controls['estado'].value;
    this.authService.ciudadesmunicipios(estado.id).subscribe((res: any) => {
      this.loading = false;
      if (res.errorMessage) {
        this.errorMessage = res.errorMessage;
      }
      else if (res) {

        this.ciudades = res.ciudades;
        this.municipiosarr = res.municipios;
        this.mostrarC = true;

      }
    }, error => {
      this.loading = false;
      let e: any = responseErrorParser(error);

      this.errorMessage = e.errorMessage;

    });
  }

  selectciudad() {
    let ciudad = this.formDireccion.controls['ciudad'].value;
    this.municipios = this.municipiosarr.filter(municipiosarr => municipiosarr.id == ciudad.id_municipio);
    this.mostrarM = true;
    this.mostrarP = false;
    this.mostrarZ = false;
    this.mostrarR = false;
    this.mostrarZP = false;
  }

  selectmunicipio() {
    let municipio = this.formDireccion.controls['municipio'].value;
    this.authService.consultaParroquias(municipio.id).subscribe((res: any) => {
      this.loading = false;
      if (res.errorMessage) {
        this.errorMessage = res.errorMessage;
      }
      else if (res) {

        this.parroquias = res;
        this.mostrarP = true;

      }
    }, error => {
      this.loading = false;
      let e: any = responseErrorParser(error);

      this.errorMessage = e.errorMessage;

    });

  }
  selectparroquia() {

    let parroquia = this.formDireccion.controls['parroquia'].value;
    this.authService.consultaZonas(parroquia.id).subscribe((res: any) => {
      this.loading = false;
      if (res.errorMessage) {
        this.errorMessage = res.errorMessage;
      }
      else if (res) {

        this.zonas = res;
        this.mostrarZ = true;

      }
    }, error => {
      this.loading = false;
      let e: any = responseErrorParser(error);

      this.errorMessage = e.errorMessage;

    });
  }

  selectsector() {
    let zona = this.formDireccion.controls['sector'].value;
    this.mostrarR = true;
    this.mostrarZP = true;
    this.zonaPostal = zona.codigo_postal;
    this.authService.consultaValores().subscribe((res: any) => {
      this.loading = false;
      if (res.errorMessage) {
        this.errorMessage = res.errorMessage;
      }
      else if (res) {

        this.sexos = res.sexo;
        this.edociviles = res.estadoCivil;
        this.profesiones = res.profesion;

      }
    }, error => {
      this.loading = false;
      let e: any = responseErrorParser(error);

      this.errorMessage = e.errorMessage;

    });
  }

  selectsexo(sexo) {

  }
  selectedocivil(edocivil) {

  }
  selectprofesion(profesion) {

  }


  // this is for the start date
  startDate = new Date(1900, 0, 1);

}

