import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectionList } from "@angular/material";

export interface actionsData {
	state: string, // State to validate after emit
	icon: string   // icon to use as button
}

export interface Column {
	id: string;
	name?: string;
	displayable?: boolean;
	exportable?: boolean;
	accesor?: Function;
	type?: string;
	footer?: string;
	actions?: actionsData[];
	align?: string;
	boldHeader?: boolean;
}

@Component({
	selector: 'app-sort-columns',
	templateUrl: './sort-columns.component.html',
	styleUrls: ['./sort-columns.component.scss']
})
export class SortColumnsComponent {

	@ViewChild(MatSelectionList) selection: MatSelectionList;

	columns: any[];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<SortColumnsComponent>
	) {
		this.columns = data.columns;
	}

	changeColumns() {
		this.dialogRef.close(this.selection.selectedOptions.selected.map((option) => option.value));
	}

}