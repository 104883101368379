import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'input[appLetrasNumbersOnly]'
})
export class LetrasNumbersOnlyDirective {

    constructor(private elementRef: ElementRef) { }

    @HostListener('input', ['$event']) onInputChange(event) {
        const initialValue = this.elementRef.nativeElement.value;
        this.elementRef.nativeElement.value = initialValue.replace(/[^0-9A-Za-z]*/g, '');
        if (initialValue !== this.elementRef.nativeElement.value) {
            event.stopPropagation();
        }
    }

}