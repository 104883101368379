import { Component, ViewChild, ViewEncapsulation, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

import { QrScannerComponent } from 'angular2-qrscanner';

@Component({
    selector: 'app-escaner-qr',
    templateUrl: 'escaner-qr.component.html',
    encapsulation: ViewEncapsulation.None
})
export class EscanerQRComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EscanerQRComponent>
    ) { }

    @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent;

    ngOnInit() {
        this.qrScannerComponent.getMediaDevices().then(devices => {
            const videoDevices: MediaDeviceInfo[] = [];
            for (const device of devices) {
                if (device.kind.toString() === 'videoinput') {
                    videoDevices.push(device);
                }
            }
            if (videoDevices.length > 0) {
                let choosenDev;
                for (const dev of videoDevices) {
                    if (dev.label.includes('front')) {
                        choosenDev = dev;
                        break;
                    }
                }
                if (choosenDev) {
                    this.qrScannerComponent.chooseCamera.next(choosenDev);
                } else {
                    this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
                }
            }
        });

        this.qrScannerComponent.capturedQr.subscribe(result => {
            this.dialogRef.close(result);
        });
    }

}