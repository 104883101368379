
import { Directive, Output, EventEmitter, HostListener} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appTextoConEspacios]'
})
export class TextoConEspaciosDirective {

    constructor(public model: NgControl) { }

    @Output() textoConEspacios: EventEmitter<any> = new EventEmitter<any>();

    @HostListener('ngModelChange', ['$event'])

    onInputChange(event: any) {
          if(event != null){
            let valor = event.replace(/[^A-Za-z ]/g, '');
            var end = valor.length;
     
            if(valor.substring(0,1) == " "){
              valor = valor.slice(0, -1);
  
              this.model.valueAccessor.writeValue(valor);
              this.textoConEspacios.emit(valor);
  
            }else if(valor.substring(end-2,end-1) == " " && valor.substring(end-1,end) == " ") {
              valor = valor.slice(0, -1);
  
              this.model.valueAccessor.writeValue(valor);
              this.textoConEspacios.emit(valor);
           }else{
              this.model.valueAccessor.writeValue(valor);
              this.textoConEspacios.emit(valor);
           }    
          
          }
       
         
     
    }

  

}