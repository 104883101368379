import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Injectable }from '@angular/core';


export const PATTERN_EMAIL = '^[_A-Za-z0-9-\\\+]+(\\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\\.[A-Za-z0-9]+)*(\\\.[A-Za-z]{2,})$';

@Injectable()
export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('contrasena').value;
  
    const confirmPassword: string = control.get('contrasenaConfirm').value;
    
    if (password !== confirmPassword) {
      control.get('contrasenaConfirm').setErrors({ NoPassswordMatch: true });
    }
  }

  static containsRepeatCharacter(control: AbstractControl) {
    const password: string = control.get('contrasena').value;
    let containsRepeat = false;
    let array = [];
    array = password ? password.split("") : [];
    let count = 0;
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      let element2 = index > 0 ? array[index - 1] : '';
      if (element == element2) {
        count++;
        if (count >= 2) {
          containsRepeat = true;
        }
      } else {
        count = 0;
      }
    }
    if (containsRepeat) {
      control.get('contrasena').setErrors({ hasThreeConsecutiveCharacters: true });
    }
  }

  static containsRepeatCharacterUser(control: AbstractControl) {
    const usuario: string = control.get('usuario').value;
    let containsRepeat = false;
    let array = [];
    array = usuario ? usuario.split("") : [];
    let count = 0;
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      let element2 = index > 0 ? array[index - 1] : '';
      if (element == element2) {
        count++;
        if (count >= 2) {
          containsRepeat = true;
        }
      } else {
        count = 0;
      }
    }
    if (containsRepeat) {
      control.get('usuario').setErrors({ hasThreeConsecutiveCharacters: true });
    }
  }

  static containsData(data: string[]): ValidatorFn {

    return (control: AbstractControl): any => {
      const password: string = control.get('contrasena').value;
      
      if (password) {
        let contain = false;
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          contain = password.includes(element);
          if(contain){
            break;
          }          
        }

        if (contain) {
          control.get('contrasena').setErrors({ hasPersonalInformation: true });
        }
      }

    }

  }

  static emailMatchValidator(control: AbstractControl) {
    let email: string = "";
    if(control.get('correo').value != undefined && control.get('correo').value != null && control.get('correo').value != ""){
      email = control.get('correo').value.replace(/[^A-Za-z0-9,._@*-]*/g, '');

    }
    let confirmEmail: string = "";
    if(control.get('correoConfirm').value != undefined && control.get('correoConfirm').value != null && control.get('correoConfirm').value != ""){
      confirmEmail = control.get('correoConfirm').value.replace(/[^A-Za-z0-9,._@*-]*/g, '');
      
    }
    if (email !== confirmEmail) {
      control.get('correoConfirm').setErrors({ NoEmailMatch: true });
    }else if( email != "" && confirmEmail != ""){
      control.get('correoConfirm').setErrors(null);
    }
  }

 /* static additionalEmailMatchValidator(control: AbstractControl) {
    const email: string = control.get('correoAdicional').value;
    const confirmEmail: string = control.get('correoAdicionalConfirm').value;
    if (email != confirmEmail) {
      control.get('correoAdicionalConfirm').setErrors({ NoEmailMatch: true });
    }else{
      control.get('correoAdicionalConfirm').setErrors({ NoEmailMatch: false });
    }
  }*/

  public static validarRif(sRif: string) {
    let bResultado: boolean = false;
    let iFactor: number = 0;

    if(sRif.indexOf( '-' )>-1){
      sRif = sRif.replace("-", "");
    }

    if (sRif.length < 10) {
      sRif = sRif.toUpperCase().substring(0, 1) + this.padLeft(sRif.substring(1, sRif.length), "0", 9);
   
    }

    let sPrimerCaracter: string = sRif.substring(0, 1).toUpperCase();
   
    switch (sPrimerCaracter) {
      case "V": iFactor = 1; break;
      case "E": iFactor = 2; break;
      case "J": iFactor = 3; break;
      case "P": iFactor = 4; break;
      case "G": iFactor = 5; break;
      case "R": iFactor = 6; break;
      case "C": iFactor = 7; break;
    }
    if (iFactor == 3 || iFactor == 5) {

     
      let suma: number;
      suma = ((+sRif.substring(8, 9)) * 2)
        + ((+sRif.substring(7, 8)) * 3)
        + ((+sRif.substring(6, 7)) * 4)
        + ((+sRif.substring(5, 6)) * 5)
        + ((+sRif.substring(4, 5)) * 6)
        + ((+sRif.substring(3, 4)) * 7)
        + ((+sRif.substring(2, 3)) * 2)
        + ((+sRif.substring(1, 2)) * 3)
        + (iFactor * 4);


       
      let resto: number = 11 - (suma % 11);
      if (resto >= 10 || resto < 1) {
        resto = 0;
      }
     
      if (sRif.substring(9, 10) == (resto + "")) {
        bResultado = true;
      }
    }else{
      bResultado = true;
    }
  
    return bResultado;
  }

  public static padLeft(valor: string, relleno: string, longitud: number) {
    let resultado: string = valor;
    let x: number = longitud - valor.length;
    let i: number = 0;

    while (i < x) {
      resultado = relleno + resultado;
      i++;
    }
    return resultado;
  }



}
